/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.note h5 {
  margin: 0; }

.note p {
  font-weight: 400;
  margin-top: 0; }

.note .please-note ul, .note .special-offers ul {
  padding-left: 1.3em; }

.note .please-note ul {
  margin-bottom: 20px; }

.note .special-offers {
  padding: 20px 12px;
  background-color: #fcf5ee; }
  .note .special-offers h4 {
    margin-top: 0;
    font-size: 1.14286em;
    font-weight: 600;
    letter-spacing: 2.7px;
    color: #3462ae; }
  .note .special-offers ul .special-offers__item {
    padding-left: 6px; }
  .note .special-offers ul .special-offers__detail {
    line-height: 1.45;
    color: #473935;
    margin: 0; }

.note .check-time {
  border: 2px solid #000;
  padding: 10px 15px; }
  .note .check-time p {
    margin-bottom: 0; }

.note .btn-warning {
  border-radius: 0;
  color: #fff;
  margin-bottom: 1em;
  border-color: #eea236;
  background-color: #f0ad4e;
  background-image: none;
  font-size: 14px;
  padding: 7px 24px; }

#hotel_detail_page .main-section .special-offers__items {
  margin-bottom: 0;
  padding-left: 17px; }

@media screen and (max-width: 767px) {
  #hotel_detail_page .main-section .special-offers__items {
    padding-left: 18px; }
  #hotel_detail_page .main-section .special-offers__item {
    font-size: 1.07143em;
    padding-left: 2px; }
  #hotel_detail_page .main-section .special-offers__detail {
    line-height: 1.53;
    color: #473935; }
    #hotel_detail_page .main-section .special-offers__detail span {
      font-weight: bold; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  #hotel_detail_page .main-section .special-offers h4 {
    font-weight: bold; } }

@media screen and (min-width: 768px) {
  #hotel_detail_page .main-section .special-offers__item {
    margin-bottom: 10px; }
  #hotel_detail_page .main-section .special-offers__detail {
    line-height: 1.6; } }

@media screen and (min-width: 992px) {
  #hotel_detail_page .main-section .special-offers__items {
    font-size: 1.07143em; }
  #hotel_detail_page .main-section .special-offers__detail {
    line-height: 1.7;
    margin-bottom: 0; } }
