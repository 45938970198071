/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
span.TlnManagedIcon {
  margin-left: 5px; }
  span.TlnManagedIcon img {
    width: 16px;
    height: 18px; }
  @media screen and (min-width: 768px) {
    span.TlnManagedIcon img {
      width: 18px;
      height: 22px; } }
  @media screen and (min-width: 992px) {
    span.TlnManagedIcon img {
      width: 15px;
      height: 17px; } }
  @media screen and (min-width: 1200px) {
    span.TlnManagedIcon img {
      width: 19px;
      height: 23px; } }

.__react_component_tooltip.type-dark {
  width: 170px;
  text-align: center;
  border-radius: 0;
  color: #fff;
  background-color: #ff9a00;
  text-transform: none;
  line-height: 1.2em;
  padding: 5px; }
  .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #ff9a00; }
  .__react_component_tooltip.type-dark.place-right:after {
    border-right-color: #ff9a00; }
