/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
@media screen and (min-width: 768px) {
  #about_footer {
    margin-bottom: 42px; } }

@media screen and (min-width: 992px) {
  #about_footer {
    margin-bottom: 52px; } }

@media screen and (min-width: 1200px) {
  #about_footer {
    margin-bottom: 62px; } }

@media screen and (max-width: 767px) {
  #about_footer .hotels-wrapper {
    margin-top: 18px;
    margin-bottom: 40px; } }
