/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.room-popup-content .modal-dialog {
  width: 518px;
  top: 0; }
  .room-popup-content .modal-dialog .room-header {
    padding: 24px 34px; }
    .room-popup-content .modal-dialog .room-header .modal-title {
      text-transform: uppercase;
      font-size: 1.21429em;
      letter-spacing: 2.67px;
      color: #625956;
      font-weight: bold; }
  .room-popup-content .modal-dialog .room-popup {
    padding: 0; }
    .room-popup-content .modal-dialog .room-popup .room-below-container .room-bedroom-types {
      display: flex;
      margin: 0; }
      .room-popup-content .modal-dialog .room-popup .room-below-container .room-bedroom-types__columns.bedroom-types-wrap {
        font-size: 1em; }
      .room-popup-content .modal-dialog .room-popup .room-below-container .room-bedroom-types .bedroom-type {
        width: 100%; }
        .room-popup-content .modal-dialog .room-popup .room-below-container .room-bedroom-types .bedroom-type span {
          font-weight: bold; }
          .room-popup-content .modal-dialog .room-popup .room-below-container .room-bedroom-types .bedroom-type span .bt {
            font-weight: normal; }
    .room-popup-content .modal-dialog .room-popup .room-below-container .room-popup-common-style__column {
      padding: 0 10px 0 0;
      width: calc(50% - 12px);
      font-size: 0.92857em; }
      .room-popup-content .modal-dialog .room-popup .room-below-container .room-popup-common-style__column strong,
      .room-popup-content .modal-dialog .room-popup .room-below-container .room-popup-common-style__column span {
        line-height: 1.36;
        color: #473935; }
    .room-popup-content .modal-dialog .room-popup .room-below-container .room-popup-common-style .room-feature-title {
      font-size: 1em;
      font-weight: bold;
      line-height: 1.92;
      color: #473935; }
    .room-popup-content .modal-dialog .room-popup .room-below-container .room-features {
      padding-left: 21px; }
      .room-popup-content .modal-dialog .room-popup .room-below-container .room-features .room-feature {
        width: 50%;
        padding-right: 25px;
        line-height: 1.45;
        color: #473935; }
    .room-popup-content .modal-dialog .room-popup .room-carousel-wrapper .carousel .carousel-control {
      background-image: none; }
      .room-popup-content .modal-dialog .room-popup .room-carousel-wrapper .carousel .carousel-control.left {
        margin-left: 14px; }
        .room-popup-content .modal-dialog .room-popup .room-carousel-wrapper .carousel .carousel-control.left span {
          transform: rotate(51deg) skew(16deg, 5deg) translateY(-50%);
          left: 0; }
      .room-popup-content .modal-dialog .room-popup .room-carousel-wrapper .carousel .carousel-control.right {
        margin-right: 14px; }
        .room-popup-content .modal-dialog .room-popup .room-carousel-wrapper .carousel .carousel-control.right span {
          right: 0;
          left: auto;
          transform: rotate(231deg) skew(17deg, 5deg) translateX(50%); }
      .room-popup-content .modal-dialog .room-popup .room-carousel-wrapper .carousel .carousel-control span {
        width: 12px;
        height: 12px;
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        margin: 0;
        top: 50%; }

.room-header .close {
  display: block;
  margin-top: 1px;
  width: 20px;
  height: 20px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIxIDIyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMTRhNjUxIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjA1NiAyLjA4MkwxOC45NDQgMjAuNU0xOC45NDQgMi4wODJMMi4wNTYgMjAuNSIvPgogICAgPC9nPgo8L3N2Zz4K) center center no-repeat; }
  .room-header .close > span {
    display: none; }

.room-popup {
  padding: 15px; }
  .room-popup__wrap {
    display: flex;
    justify-content: space-between;
    padding: 10px 34px 0 34px; }
  .room-popup .btn-close {
    background-color: #000;
    border: 2px solid #fff;
    box-shadow: 0 0 1px #000;
    color: #fff;
    padding: 0;
    position: absolute;
    right: -15px;
    top: -15px; }
    .room-popup .btn-close:hover {
      color: #fff;
      opacity: 0.8; }
  .room-popup .row img.icon {
    margin-right: 5px; }
  .room-popup .row-border-top {
    padding: 13px 34px;
    border-top: solid 1px #d9d7d6; }
  .room-popup .room-carousel {
    max-height: 400px; }
    .room-popup .room-carousel .carousel-inner {
      height: 300px; }
      .room-popup .room-carousel .carousel-inner img {
        object-fit: cover; }
    .room-popup .room-carousel-detail .carousel-inner {
      height: 110px;
      padding: 0; }
  @media screen and (min-width: 992px) {
    .room-popup .title-and-desc h3 {
      margin-top: 0; } }
  .room-popup .carousel .carousel-control.left span {
    left: 86%; }
    @media screen and (max-width: 767px) {
      .room-popup .carousel .carousel-control.left span {
        left: 50%; } }
  .room-popup .carousel .carousel-control.right span {
    left: 100%; }
    @media screen and (max-width: 767px) {
      .room-popup .carousel .carousel-control.right span {
        left: 65%; } }
  .room-popup .carousel .carousel-control.left span, .room-popup .carousel .carousel-control.right span {
    height: 20px;
    margin-top: 0;
    position: absolute;
    top: 45%;
    width: 20px; }
    @media screen and (max-width: 767px) {
      .room-popup .carousel .carousel-control.left span, .room-popup .carousel .carousel-control.right span {
        top: 43%; } }
  .room-popup .room-popup-info-separator {
    margin-top: 10px;
    margin-bottom: 10px; }
  .room-popup .room-features-wrapper {
    padding-top: 7px;
    padding-bottom: 38px; }
    .room-popup .room-features-wrapper h5 {
      margin: 0; }
  .room-popup .form-inline .form-group + .form-group {
    margin-left: 5px; }
  .room-popup .room-carousel-wrapper {
    padding-bottom: 3px;
    width: 194px; }
    .room-popup .room-carousel-wrapper .room-max-occupancy {
      margin-top: 10px;
      font-size: 0.92857em; }
      .room-popup .room-carousel-wrapper .room-max-occupancy strong,
      .room-popup .room-carousel-wrapper .room-max-occupancy span {
        line-height: 2.27;
        color: #473935; }
  .room-popup .title-and-desc {
    padding: 0;
    width: calc(100% - 194px - 20px); }
    .room-popup .title-and-desc .description {
      padding-top: 5px;
      font-size: 0.92857em; }
      .room-popup .title-and-desc .description span {
        line-height: 1.36;
        color: #473935; }
