/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
@media screen and (min-width: 768px) {
  #hotels_list .row .hotel-list-item {
    padding-left: 20px; } }

@media screen and (min-width: 992px) {
  #hotels_list .list-on-a-line {
    margin-left: 0;
    margin-right: 0; }
  #hotels_list .row .hotel-list-item {
    padding: 0 7px; }
    #hotels_list .row .hotel-list-item .hotel-info {
      padding: 10px 12px 10px 15px; }
      #hotels_list .row .hotel-list-item .hotel-info .actions {
        padding-right: 14px; }
      #hotels_list .row .hotel-list-item .hotel-info span.TlnManagedIcon {
        margin-left: 9px; } }
    @media screen and (min-width: 992px) and (min-width: 1200px) {
      #hotels_list .row .hotel-list-item .hotel-info {
        padding: 9px 18px 16px; } }

@media screen and (min-width: 992px) {
    #hotels_list .row .hotel-list-item:nth-child(1) {
      padding-left: 10px;
      padding-right: 8px; }
    #hotels_list .row .hotel-list-item:nth-child(2) {
      padding-left: 18px;
      padding-right: 0; } }

@media screen and (min-width: 1200px) {
  #hotels_list .row .hotel-list-item:nth-child(1) {
    padding-left: 20px; }
  #hotels_list .row .hotel-list-item:nth-child(2) {
    padding-left: 28px; } }

@media screen and (max-width: 767px) {
  .hotel-more-info.modal-dialog button.close {
    color: #fff;
    opacity: 1; }
  .hotel-more-info.modal-dialog .modal-content {
    border-radius: 0; }
    .hotel-more-info.modal-dialog .modal-content .close-note {
      position: absolute;
      right: 5px;
      top: 5px;
      background: none;
      -webkit-appearance: none; }
      .hotel-more-info.modal-dialog .modal-content .close-note i {
        color: #fff; }
    .hotel-more-info.modal-dialog .modal-content .modal-header {
      background-color: #3462ae;
      color: #fff;
      font-size: 18px;
      margin: 0; }
    .hotel-more-info.modal-dialog .modal-content ul {
      list-style: none;
      padding: 0 14px; }
      .hotel-more-info.modal-dialog .modal-content ul li {
        margin: 0.71429em 0;
        padding-left: 1em; }
        .hotel-more-info.modal-dialog .modal-content ul li:before {
          border-color: transparent #000;
          border-style: solid;
          border-width: 5px 0 5px 6px;
          content: "";
          display: block;
          height: 0;
          left: -1em;
          position: relative;
          top: 1em;
          width: 0; }
      .hotel-more-info.modal-dialog .modal-content ul li:first-child {
        margin-top: 0; }
  .common-the-hot-list {
    width: 100%; }
    .common-the-hot-list .slick-track .slick-slide {
      padding-left: 7px;
      padding-right: 7px; }
      .common-the-hot-list .slick-track .slick-slide .hotel-list-item {
        min-height: 16.07143em; }
        .common-the-hot-list .slick-track .slick-slide .hotel-list-item .hotel {
          margin-bottom: 10px; } }

.hotel-list-item .managed-by-tln-ribbon {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  width: 90px;
  height: 90px;
  text-align: right;
  display: none; }
  .hotel-list-item .managed-by-tln-ribbon .text {
    font-size: 11px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 140px;
    display: block;
    background: #14a651;
    box-shadow: 0 3px 10px -5px black;
    position: absolute;
    top: 25px;
    left: -32px; }

.hotel-list-item .hotel {
  position: relative;
  background: #fcf5ee;
  margin-bottom: 30px; }
  .hotel-list-item .hotel .promo-ribbon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 18px;
    background-color: #14a651;
    z-index: 1;
    color: #fff;
    padding: 3px 10px;
    font-weight: 700; }
    .hotel-list-item .hotel .promo-ribbon > img {
      margin-right: 5px; }
  @media screen and (max-width: 767px) {
    .hotel-list-item .hotel {
      margin-bottom: 30px;
      position: relative; }
      .hotel-list-item .hotel .hotel-info,
      .hotel-list-item .hotel .hotel-info .hotel-more-info.open,
      .hotel-list-item .hotel .hotel-info .text-right.actions,
      .hotel-list-item .hotel .hotel-info .text-right.actions .hotel-more-info.open,
      .hotel-list-item .hotel .hotel-info .villa-name,
      .hotel-list-item .hotel .hotel-info .villa-name .hotel-more-info.open,
      .hotel-list-item .hotel .hotel-info .villa-name .text-right.actions,
      .hotel-list-item .hotel .hotel-info .villa-name .text-right.actions .hotel-more-info.open {
        position: unset; }
        .hotel-list-item .hotel .hotel-info .dropdown-menu,
        .hotel-list-item .hotel .hotel-info .hotel-more-info.open .dropdown-menu,
        .hotel-list-item .hotel .hotel-info .text-right.actions .dropdown-menu,
        .hotel-list-item .hotel .hotel-info .text-right.actions .hotel-more-info.open .dropdown-menu,
        .hotel-list-item .hotel .hotel-info .villa-name .dropdown-menu,
        .hotel-list-item .hotel .hotel-info .villa-name .hotel-more-info.open .dropdown-menu,
        .hotel-list-item .hotel .hotel-info .villa-name .text-right.actions .dropdown-menu,
        .hotel-list-item .hotel .hotel-info .villa-name .text-right.actions .hotel-more-info.open .dropdown-menu {
          top: 0;
          bottom: auto;
          width: 100%;
          left: 0; }
          .hotel-list-item .hotel .hotel-info .dropdown-menu ul,
          .hotel-list-item .hotel .hotel-info .hotel-more-info.open .dropdown-menu ul,
          .hotel-list-item .hotel .hotel-info .text-right.actions .dropdown-menu ul,
          .hotel-list-item .hotel .hotel-info .text-right.actions .hotel-more-info.open .dropdown-menu ul,
          .hotel-list-item .hotel .hotel-info .villa-name .dropdown-menu ul,
          .hotel-list-item .hotel .hotel-info .villa-name .hotel-more-info.open .dropdown-menu ul,
          .hotel-list-item .hotel .hotel-info .villa-name .text-right.actions .dropdown-menu ul,
          .hotel-list-item .hotel .hotel-info .villa-name .text-right.actions .hotel-more-info.open .dropdown-menu ul {
            min-height: 100px;
            max-height: 206px;
            overflow-y: scroll; } }
  .hotel-list-item .hotel a.img-container {
    display: block;
    min-height: 137px;
    position: relative; }
    .hotel-list-item .hotel a.img-container .bil-image {
      height: 137px; }
    @media screen and (min-width: 768px) {
      .hotel-list-item .hotel a.img-container {
        min-height: 106px; }
        .hotel-list-item .hotel a.img-container .bil-image {
          height: 106px; } }
    @media screen and (min-width: 992px) {
      .hotel-list-item .hotel a.img-container {
        min-height: 137px; }
        .hotel-list-item .hotel a.img-container .bil-image {
          height: 137px; } }
    @media screen and (min-width: 1200px) {
      .hotel-list-item .hotel a.img-container .bil-image {
        height: 157px; } }
  .hotel-list-item .hotel .hotel-photos li {
    margin: 1px 3px; }
    @media screen and (min-width: 1200px) {
      .hotel-list-item .hotel .hotel-photos li {
        margin: 1px 4px; } }
  .hotel-list-item .hotel .hotel-photos .img-container {
    min-width: 168px; }
  .hotel-list-item .hotel .hotel-photos .img-container .bil-image {
    height: 168px; }
    @media screen and (min-width: 768px) {
      .hotel-list-item .hotel .hotel-photos .img-container .bil-image {
        height: 243px; } }
    @media screen and (min-width: 992px) {
      .hotel-list-item .hotel .hotel-photos .img-container .bil-image {
        height: 194px; } }
    @media screen and (min-width: 1200px) {
      .hotel-list-item .hotel .hotel-photos .img-container .bil-image {
        height: 253px; } }
  .hotel-list-item .hotel .hotel-photos .hotel-photos__button {
    background-image: none;
    display: none; }
    .hotel-list-item .hotel .hotel-photos .hotel-photos__button span {
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      top: 47%;
      height: 17px;
      width: 17px; }
  .hotel-list-item .hotel .hotel-photos .hotel-photos__button-prev span {
    margin-left: -4px;
    transform: rotate(51deg) skew(14deg, 2deg); }
  .hotel-list-item .hotel .hotel-photos .hotel-photos__button-next span {
    margin-left: -6px;
    transform: rotate(231deg) skew(14deg, 2deg); }
  .hotel-list-item .hotel .first-row {
    margin-bottom: 2px; }
    @media screen and (min-width: 992px) {
      .hotel-list-item .hotel .first-row {
        margin-bottom: 6px; } }
  .hotel-list-item .hotel .hotel-summary {
    display: flex;
    align-items: flex-end;
    color: #615956;
    padding-right: 0;
    font-size: 0.85714em; }
    .hotel-list-item .hotel .hotel-summary .bedroom-summary img,
    .hotel-list-item .hotel .hotel-summary .shower-summary img {
      display: inline-block;
      margin-left: 5px;
      margin-top: -5px; }
    @media screen and (min-width: 768px) {
      .hotel-list-item .hotel .hotel-summary .shower-summary img {
        height: 23px; } }
    @media screen and (min-width: 992px) {
      .hotel-list-item .hotel .hotel-summary .bedroom-summary img {
        width: 22px;
        height: 15px; }
      .hotel-list-item .hotel .hotel-summary .shower-summary img {
        height: 18px; } }
    @media screen and (min-width: 1200px) {
      .hotel-list-item .hotel .hotel-summary .bedroom-summary img {
        width: 29px;
        height: 18px; }
      .hotel-list-item .hotel .hotel-summary .shower-summary img {
        height: 22px; } }
    .hotel-list-item .hotel .hotel-summary .inline {
      display: inline-block;
      margin-left: 12px; }
      .hotel-list-item .hotel .hotel-summary .inline:first-child {
        margin-left: 0; }
    @media screen and (min-width: 768px) {
      .hotel-list-item .hotel .hotel-summary {
        font-size: 1.14286em; }
        .hotel-list-item .hotel .hotel-summary .inline {
          margin-left: 15px; } }
    @media screen and (min-width: 992px) {
      .hotel-list-item .hotel .hotel-summary {
        font-size: 1em; } }
    @media screen and (min-width: 1200px) {
      .hotel-list-item .hotel .hotel-summary {
        font-size: 1.14286em; } }
  .hotel-list-item .hotel .actions {
    padding-left: 0;
    display: flex;
    justify-content: flex-end; }
    @media screen and (max-width: 767px) {
      .hotel-list-item .hotel .actions {
        flex-wrap: wrap; } }
    @media screen and (min-width: 1200px) {
      .hotel-list-item .hotel .actions {
        padding-right: 15px; } }
    .hotel-list-item .hotel .actions > a, .hotel-list-item .hotel .actions > button.hotel-more-info, .hotel-list-item .hotel .actions > .hotel-more-info button {
      cursor: pointer;
      width: 20px;
      height: 20px;
      padding: 2px 4px;
      border-radius: 50%;
      margin-left: 5px;
      display: inline-block;
      box-shadow: none;
      border: none;
      font-size: 0.85714em;
      background: none #14a651; }
      .hotel-list-item .hotel .actions > a:hover, .hotel-list-item .hotel .actions > a:active, .hotel-list-item .hotel .actions > button.hotel-more-info:hover, .hotel-list-item .hotel .actions > button.hotel-more-info:active, .hotel-list-item .hotel .actions > .hotel-more-info button:hover, .hotel-list-item .hotel .actions > .hotel-more-info button:active {
        background-color: #0c6230; }
    .hotel-list-item .hotel .actions .TlnManagedIcon span {
      display: flex; }
    .hotel-list-item .hotel .actions .TlnManagedIcon img {
      height: 100%; }
    @media screen and (min-width: 768px) {
      .hotel-list-item .hotel .actions > a,
      .hotel-list-item .hotel .actions > button.hotel-more-info,
      .hotel-list-item .hotel .actions > .hotel-more-info button {
        width: 27px;
        height: 27px;
        font-size: 1.28571em;
        padding-right: 5px;
        padding-left: 5px; }
      .hotel-list-item .hotel .actions .TlnManagedIcon img {
        width: 24px; } }
    @media screen and (min-width: 992px) {
      .hotel-list-item .hotel .actions > a,
      .hotel-list-item .hotel .actions > button.hotel-more-info,
      .hotel-list-item .hotel .actions > .hotel-more-info button {
        width: 21px;
        height: 21px;
        padding: 2px 4px;
        font-size: 0.85714em; }
      .hotel-list-item .hotel .actions .TlnManagedIcon img {
        width: 19px; } }
    @media screen and (min-width: 1200px) {
      .hotel-list-item .hotel .actions > a,
      .hotel-list-item .hotel .actions > button.hotel-more-info,
      .hotel-list-item .hotel .actions > .hotel-more-info button {
        width: 27px;
        height: 27px;
        font-size: 1.28571em;
        padding-right: 5px;
        padding-left: 5px; }
      .hotel-list-item .hotel .actions .TlnManagedIcon img {
        width: 24px; } }
    .hotel-list-item .hotel .actions .hotel-more-info .dropdown-menu {
      top: auto;
      left: auto;
      right: -100%;
      bottom: 0;
      width: 300px;
      color: #000;
      font-family: "Nunito Sans", sans-serif;
      border-radius: 4px;
      padding: 0;
      margin: 0; }
      @media screen and (max-width: 767px) {
        .hotel-list-item .hotel .actions .hotel-more-info .dropdown-menu {
          bottom: 130%;
          z-index: 3; } }
      .hotel-list-item .hotel .actions .hotel-more-info .dropdown-menu .dropdown-content .close-note {
        position: absolute;
        right: 10px;
        top: 11px;
        width: 20px;
        background: none;
        -webkit-appearance: none; }
        .hotel-list-item .hotel .actions .hotel-more-info .dropdown-menu .dropdown-content .close-note i {
          color: #fff; }
      .hotel-list-item .hotel .actions .hotel-more-info .dropdown-menu .dropdown-content h4 {
        padding: 14px;
        background-color: #3462ae;
        color: #fff;
        font-size: 18px;
        margin: 0; }
      .hotel-list-item .hotel .actions .hotel-more-info .dropdown-menu .dropdown-content ul {
        list-style: none;
        padding: 0 14px; }
        .hotel-list-item .hotel .actions .hotel-more-info .dropdown-menu .dropdown-content ul li {
          margin: 10px 0;
          padding-left: 1em; }
          .hotel-list-item .hotel .actions .hotel-more-info .dropdown-menu .dropdown-content ul li:before {
            border-color: transparent #000;
            border-style: solid;
            border-width: 5px 0 5px 6px;
            content: "";
            display: block;
            height: 0;
            left: -1em;
            position: relative;
            top: 1em;
            width: 0; }
        .hotel-list-item .hotel .actions .hotel-more-info .dropdown-menu .dropdown-content ul li:first-child {
          margin-top: 0; }
  .hotel-list-item .hotel .hotel-info {
    color: #615956;
    padding: 9px 8px 9px 13px; }
    .hotel-list-item .hotel .hotel-info .villa-name {
      display: flex;
      align-items: center; }
      .hotel-list-item .hotel .hotel-info .villa-name .hotel-location {
        padding-right: 0; }
    .hotel-list-item .hotel .hotel-info .hotel-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #615956;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 400;
      font-size: 1.21429em;
      line-height: 1.1;
      display: block; }
      .hotel-list-item .hotel .hotel-info .hotel-name:hover {
        text-decoration: none; }
      @media screen and (min-width: 768px) {
        .hotel-list-item .hotel .hotel-info .hotel-name {
          font-size: 1.71429em; } }
      @media screen and (min-width: 992px) {
        .hotel-list-item .hotel .hotel-info .hotel-name {
          font-size: 1.42857em; } }
      @media screen and (min-width: 1200px) {
        .hotel-list-item .hotel .hotel-info .hotel-name {
          font-size: 1.71429em; } }
    .hotel-list-item .hotel .hotel-info .tooltip {
      z-index: 18; }
    .hotel-list-item .hotel .hotel-info .address-short {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-height: 2.4em;
      font-size: 0.78571em;
      letter-spacing: 1px;
      line-height: 1.2;
      color: #615956;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase; }
      @media screen and (min-width: 768px) {
        .hotel-list-item .hotel .hotel-info .address-short {
          font-size: 1em;
          line-height: 1.2; } }
      @media screen and (min-width: 992px) {
        .hotel-list-item .hotel .hotel-info .address-short {
          font-size: 0.85714em; } }
      @media screen and (min-width: 1200px) {
        .hotel-list-item .hotel .hotel-info .address-short {
          font-size: 1em; } }
    .hotel-list-item .hotel .hotel-info .price-col {
      padding-left: 0;
      padding-right: 16px;
      line-height: 1; }
      @media screen and (max-width: 767px) {
        .hotel-list-item .hotel .hotel-info .price-col__price {
          font-size: 1.07143em; } }
      @media screen and (min-width: 768px) {
        .hotel-list-item .hotel .hotel-info .price-col__price {
          font-size: 1.14286em; } }
      @media screen and (min-width: 992px) {
        .hotel-list-item .hotel .hotel-info .price-col__price {
          font-size: 1em; } }
      @media screen and (min-width: 1200px) {
        .hotel-list-item .hotel .hotel-info .price-col__price {
          font-size: 1.14286em; } }
      .hotel-list-item .hotel .hotel-info .price-col__price > span {
        color: #3462ae; }
      .hotel-list-item .hotel .hotel-info .price-col__price .discount-price-prefix {
        font-weight: 200;
        font-style: italic; }
      .hotel-list-item .hotel .hotel-info .price-col .tooltip {
        opacity: 1;
        padding: 5px 9px; }
      .hotel-list-item .hotel .hotel-info .price-col .price {
        letter-spacing: -.2px;
        color: #3462ae;
        font-weight: 600;
        line-height: 1.1; }
        .hotel-list-item .hotel .hotel-info .price-col .price span.currency, .hotel-list-item .hotel .hotel-info .price-col .price span.amount, .hotel-list-item .hotel .hotel-info .price-col .price span.time-unit {
          display: inline-block; }
        .hotel-list-item .hotel .hotel-info .price-col .price span.currency, .hotel-list-item .hotel .hotel-info .price-col .price span.amount {
          font-weight: bold; }
        .hotel-list-item .hotel .hotel-info .price-col .price span.time-unit {
          margin-left: 6px; }
    .hotel-list-item .hotel .hotel-info hr {
      margin: 6px 0 8px 0;
      border-color: #d9d7d6; }
      @media screen and (min-width: 768px) {
        .hotel-list-item .hotel .hotel-info hr {
          margin-top: 8px;
          margin-bottom: 12px; } }
      @media screen and (min-width: 992px) {
        .hotel-list-item .hotel .hotel-info hr {
          margin: 7px 0 9px 0; } }
      @media screen and (min-width: 1200px) {
        .hotel-list-item .hotel .hotel-info hr {
          margin-bottom: 14px; } }
    @media screen and (min-width: 768px) {
      .hotel-list-item .hotel .hotel-info--hot-list .hotel-name {
        font-size: 1em; }
      .hotel-list-item .hotel .hotel-info--hot-list .address-short {
        font-size: 0.71429em; }
      .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary {
        font-size: 0.78571em; }
        .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary .bedroom-summary img,
        .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary .shower-summary img {
          margin-left: 2px; }
        .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary .bedroom-summary img {
          width: 15px;
          height: 10px; }
        .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary .shower-summary img {
          height: 13px; }
      .hotel-list-item .hotel .hotel-info--hot-list .actions > a, .hotel-list-item .hotel .hotel-info--hot-list .actions > button.hotel-more-info, .hotel-list-item .hotel .hotel-info--hot-list .actions > .hotel-more-info button {
        width: 16px;
        height: 16px;
        font-size: 0.71429em;
        padding: 1px 3px; }
      .hotel-list-item .hotel .hotel-info--hot-list .price-col__price {
        font-size: 0.78571em; } }
    @media screen and (min-width: 992px) {
      .hotel-list-item .hotel .hotel-info--hot-list {
        margin-bottom: 10px; }
        .hotel-list-item .hotel .hotel-info--hot-list .hotel-name {
          font-size: 1.14286em; }
        .hotel-list-item .hotel .hotel-info--hot-list .address-short {
          font-size: 0.71429em; }
        .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary {
          font-size: 0.85714em; }
          .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary .bedroom-summary img,
          .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary .shower-summary img {
            margin-left: 4px; }
          .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary .bedroom-summary img {
            width: 19px;
            height: 12px; }
          .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary .shower-summary img {
            height: 16px; }
        .hotel-list-item .hotel .hotel-info--hot-list hr {
          margin-top: 4px;
          margin-bottom: 8px; }
        .hotel-list-item .hotel .hotel-info--hot-list .actions > a, .hotel-list-item .hotel .hotel-info--hot-list .actions > button.hotel-more-info, .hotel-list-item .hotel .hotel-info--hot-list .actions > .hotel-more-info button {
          width: 18px;
          height: 19px;
          padding: 2px 3px;
          font-size: 0.85714em; }
        .hotel-list-item .hotel .hotel-info--hot-list .price-col__price {
          font-size: 0.85714em; } }
    @media screen and (min-width: 1200px) {
      .hotel-list-item .hotel .hotel-info--hot-list .hotel-name {
        font-size: 1.28571em; }
      .hotel-list-item .hotel .hotel-info--hot-list .address-short {
        font-size: 0.85714em; }
      .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary {
        font-size: 1.14286em; }
        .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary .bedroom-summary img {
          width: 22px;
          height: 15px; }
        .hotel-list-item .hotel .hotel-info--hot-list .hotel-summary .shower-summary img {
          height: 18px; }
      .hotel-list-item .hotel .hotel-info--hot-list .price-col__price {
        font-size: 1.14286em; } }
  @media screen and (min-width: 768px) {
    .hotel-list-item .hotel .hotel-info {
      padding: 12px 8px 14px 13px; } }
  @media screen and (min-width: 992px) {
    .hotel-list-item .hotel .hotel-info {
      padding: 10px 16px 14px; } }

.hotel-list-item .hotel:hover .hotel-photos {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0) 51%, rgba(0, 0, 0, 0.3)); }

.hotel-list-item .hotel:hover .hotel-photos__button {
  display: block; }
