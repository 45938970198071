/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
a.apply-coupon-toggle {
  display: block;
  font-size: 1em;
  text-decoration: underline;
  cursor: pointer; }

a.remove-coupon {
  color: #C71C22; }

.payment-coupon-wrapper .coupon-input-wrapper {
  height: 0;
  margin-top: 1em;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out; }
  .payment-coupon-wrapper .coupon-input-wrapper.open {
    height: auto; }
  .payment-coupon-wrapper .coupon-input-wrapper button {
    padding: 8px 15px; }
