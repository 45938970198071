/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
body .navbar-header {
  position: relative;
  z-index: 1; }

body #navbar-main {
  position: relative; }
  body #navbar-main .nav.navbar-right li.dropdown.open.destination-menu {
    position: static; }

@media screen and (max-width: 767px) {
  body .filter-mobile .destination-menu.open.select > .dropdown-menu,
  body .search-container .destination-menu.open.select > .dropdown-menu {
    max-height: 500px;
    left: 0;
    top: 90%;
    width: 100%;
    background-color: #fff; }
    body .filter-mobile .destination-menu.open.select > .dropdown-menu li a.dropdown-toggle,
    body .search-container .destination-menu.open.select > .dropdown-menu li a.dropdown-toggle {
      box-shadow: none;
      margin-top: 5px; }
    body .filter-mobile .destination-menu.open.select > .dropdown-menu li.open ul.dropdown-menu,
    body .search-container .destination-menu.open.select > .dropdown-menu li.open ul.dropdown-menu {
      position: relative;
      display: block;
      width: 100%;
      margin-top: 5px; } }

@media screen and (max-width: 767px) {
  body .search-container .destination-menu.open > .dropdown-menu {
    border-radius: 0;
    background-color: transparent;
    border: solid 1px #C4C5C7;
    margin-top: 5px;
    padding-right: 30px; }
    body .search-container .destination-menu.open > .dropdown-menu .top-mega-menu-mobile li a.dropdown-toggle {
      font-size: 1.14286em; } }

body .destination-menu.open.select > .dropdown-menu {
  width: 845px;
  left: 101%;
  top: -62px; }
  @media screen and (max-width: 1199px) and (min-width: 992px) and (orientation: landscape) {
    body .destination-menu.open.select > .dropdown-menu {
      width: 596px; } }
  @media screen and (max-width: 991px) and (min-width: 768px) and (orientation: portrait) {
    body .destination-menu.open.select > .dropdown-menu {
      width: 392px; } }

body .destination-menu.open .dropdown-menu {
  width: auto;
  left: 10%;
  right: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  margin-top: 9px; }
  @media screen and (min-width: 1200px) {
    body .destination-menu.open .dropdown-menu {
      left: 25%; } }
  @media screen and (max-width: 767px) {
    body .destination-menu.open .dropdown-menu {
      left: 0;
      margin-top: 0; } }
  @media screen and (max-width: 991px) and (min-width: 768px) and (orientation: portrait) {
    body .destination-menu.open .dropdown-menu {
      left: 0; } }

body .destination-menu.open .top-mega-menu-mobile {
  padding-left: 30px; }
  body .destination-menu.open .top-mega-menu-mobile > li > a {
    padding: 5px 0; }
  body .destination-menu.open .top-mega-menu-mobile > li.open > a {
    color: #14a651;
    background: none; }
  body .destination-menu.open .top-mega-menu-mobile > li ul.dropdown-menu li.city a {
    text-transform: capitalize;
    padding-left: 35px; }
  body .destination-menu.open .top-mega-menu-mobile > li ul.dropdown-menu li.country a {
    font-weight: 700; }

body .destination-menu.open .top-mega-menu ul li a {
  color: #000;
  padding: 3px 10px; }
  @media screen and (min-width: 768px) {
    body .destination-menu.open .top-mega-menu ul li a {
      padding: 6px 10px; } }

body .destination-menu.open .top-mega-menu .left-col {
  height: 270px;
  background-color: #F5F0EF;
  border: solid 1px #C4C5C7;
  padding: 15px 0; }
  body .destination-menu.open .top-mega-menu .left-col ul li a {
    font-weight: 700;
    display: block;
    padding: 5px 15px; }
    body .destination-menu.open .top-mega-menu .left-col ul li a.active {
      border-top: solid 1px #C4C5C7;
      border-bottom: solid 1px #C4C5C7;
      background-color: #fff;
      border-right: solid 1px #fff;
      margin-right: -1px;
      color: #14a651; }
    body .destination-menu.open .top-mega-menu .left-col ul li a:hover {
      color: #14a651; }

body .destination-menu.open .top-mega-menu .main-col {
  padding: 0; }
  body .destination-menu.open .top-mega-menu .main-col > div {
    display: none;
    background-color: #fff;
    border: solid 1px #C4C5C7;
    border-left-width: 0;
    padding: 15px 0;
    min-height: 270px;
    width: 100%; }
    body .destination-menu.open .top-mega-menu .main-col > div.active {
      display: block; }
      body .destination-menu.open .top-mega-menu .main-col > div.active .wrapper {
        display: flex; }
    @media screen and (min-width: 1200px) {
      body .destination-menu.open .top-mega-menu .main-col > div.col-4 {
        width: 86%; }
      body .destination-menu.open .top-mega-menu .main-col > div.col-2 {
        width: 65%; }
      body .destination-menu.open .top-mega-menu .main-col > div.col-1 {
        width: 65%; }
      body .destination-menu.open .top-mega-menu .main-col > div.col-6 {
        width: 100%; }
      body .destination-menu.open .top-mega-menu .main-col > div.col-5 {
        width: 100%; } }
    body .destination-menu.open .top-mega-menu .main-col > div.top-destinations ul li a {
      display: block;
      text-align: center;
      padding: 15px;
      text-transform: capitalize; }
      body .destination-menu.open .top-mega-menu .main-col > div.top-destinations ul li a img {
        margin-bottom: 5px; }
    body .destination-menu.open .top-mega-menu .main-col > div.top-destinations ul li:first-child a {
      padding-top: 0; }
    body .destination-menu.open .top-mega-menu .main-col > div ul {
      flex: 1 1 0; }
      body .destination-menu.open .top-mega-menu .main-col > div ul:first-child {
        padding-left: 15px; }
      body .destination-menu.open .top-mega-menu .main-col > div ul:last-child {
        padding-right: 15px; }
      body .destination-menu.open .top-mega-menu .main-col > div ul li a {
        font-size: 16px; }
        body .destination-menu.open .top-mega-menu .main-col > div ul li a:hover {
          color: #14a651; }
      body .destination-menu.open .top-mega-menu .main-col > div ul li.city a {
        display: block;
        text-transform: capitalize; }
      body .destination-menu.open .top-mega-menu .main-col > div ul li.country a {
        font-weight: 700; }
      body .destination-menu.open .top-mega-menu .main-col > div ul li.country:not(:first-child) {
        margin-top: 20px; }
