/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
@media screen and (max-width: 767px) {
  .wedding-enquiry__form .row .info-wrapper {
    padding-left: 0;
    padding-right: 0; }
  .wedding-enquiry__form .row .wedding-enquiry__checkin-info {
    margin-bottom: 22px; }
    .wedding-enquiry__form .row .wedding-enquiry__checkin-info > .dropdown-pax .search-input {
      padding: 8px 18px; }
  .wedding-enquiry__form .row .wedding-enquiry__checkin-date {
    margin-bottom: 0; }
  .wedding-enquiry__form .row .wedding-enquiry__btn-submit {
    width: 100%; } }

.wedding-enquiry .error-message,
.wedding-enquiry .thank-message {
  margin-top: 10px; }

.wedding-enquiry .thank-message {
  color: #14a651;
  font-weight: 600; }

.enquiry-form .modal-body {
  padding-top: 0; }
