/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.currency-dropdown-wrapper {
  margin: auto; }

@media screen and (max-width: 767px) {
  .quick-clear-filter {
    position: relative;
    z-index: 1000;
    top: 0;
    background: #fff;
    padding: 24px 34px 14px 34px;
    justify-content: space-between; }
    .quick-clear-filter__link {
      font-size: 1.35714em;
      letter-spacing: 3.2px;
      text-decoration: underline; }
    .quick-clear-filter__icon {
      background: none;
      border: none; }
    .quick-clear-filter__image-icon {
      width: 1.35714em;
      height: 1.35714em;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIxIDIyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMTRhNjUxIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjA1NiAyLjA4MkwxOC45NDQgMjAuNU0xOC45NDQgMi4wODJMMi4wNTYgMjAuNSIvPgogICAgPC9nPgo8L3N2Zz4K) center center no-repeat;
      text-indent: 100%;
      overflow: hidden; } }

@media screen and (max-width: 767px) {
  .filter-mobile.modal {
    padding-left: 0 !important; }
    .filter-mobile.modal .modal-dialog {
      margin: 0;
      border-radius: 0;
      width: auto; }
      .filter-mobile.modal .modal-dialog .modal-content {
        border-radius: 0; }
        .filter-mobile.modal .modal-dialog .modal-content .modal-body {
          padding: 0; }
          .filter-mobile.modal .modal-dialog .modal-content .modal-body .btn-apply {
            font-size: 1.3em;
            text-transform: uppercase;
            margin: 15px auto; } }
        @media screen and (max-width: 767px) and (max-width: 767px) {
          .filter-mobile.modal .modal-dialog .modal-content .modal-body .btn-apply {
            width: 100%;
            font-size: 1.14286em;
            font-weight: 600;
            letter-spacing: 1.9px;
            padding: 7px 0;
            margin: 10px auto; }
          .filter-mobile.modal .modal-dialog .modal-content .modal-body .filter-search-btn {
            position: relative;
            bottom: 0;
            background-color: #fff; }
            .filter-mobile.modal .modal-dialog .modal-content .modal-body .filter-search-btn__wrap {
              padding: 0 34px;
              border-bottom: 1px solid #d9d7d6; } }

@media screen and (max-width: 767px) {
  .filter-button-wrapper {
    position: relative;
    text-align: center;
    margin: 0 auto;
    padding-top: 70px;
    padding-left: 32px;
    padding-right: 32px;
    z-index: 3; }
    .filter-button-wrapper .search-box {
      margin-top: 5px; } }
    @media screen and (max-width: 767px) and (max-width: 767px) {
      .filter-button-wrapper .search-box .input-group {
        background-color: #fff;
        padding: 4px 0; }
        .filter-button-wrapper .search-box .input-group .search-text {
          text-transform: uppercase; }
        .filter-button-wrapper .search-box .input-group .form-control {
          letter-spacing: 2.3px;
          color: #3462ae;
          height: 25px;
          padding: 3px 10px;
          font-size: 0.92857em;
          font-weight: 300; }
          .filter-button-wrapper .search-box .input-group .form-control::placeholder {
            color: #3462ae; } }
    @media screen and (max-width: 767px) and (max-width: 991px) and (min-width: 768px) and (orientation: portrait) {
      .filter-button-wrapper .search-box .input-group {
        width: 100%; } }

@media screen and (max-width: 767px) {
      .filter-button-wrapper .search-box > form input::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #000; }
      .filter-button-wrapper .search-box > form input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #000;
        opacity: 1; }
      .filter-button-wrapper .search-box > form input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #000;
        opacity: 1; }
      .filter-button-wrapper .search-box > form input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #000; }
      .filter-button-wrapper .search-box > form input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #000; }
      .filter-button-wrapper .search-box > form input:hover, .filter-button-wrapper .search-box > form input:active, .filter-button-wrapper .search-box > form input:focus {
        background-color: transparent; }
      .filter-button-wrapper .search-box input {
        border: none;
        background: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        border-radius: 0;
        padding-bottom: 0; }
        .filter-button-wrapper .search-box input:focus {
          background-color: transparent !important; }
      .filter-button-wrapper .search-box .input-group-btn button.btn-default {
        background: none;
        border: none;
        padding: 0 10px 3px 0;
        color: #ff9a00; }
        .filter-button-wrapper .search-box .input-group-btn button.btn-default i {
          font-size: 1.14286em;
          color: #14a651; }
    .filter-button-wrapper .btn-filter-mobile {
      width: 100%;
      bottom: 20px;
      left: 20%;
      font-weight: 600;
      letter-spacing: 1.6px;
      padding: 7px 20px; } }
    @media screen and (max-width: 767px) and (max-width: 767px) {
      .filter-button-wrapper .btn-filter-mobile {
        margin-top: 14px; } }
    @media screen and (max-width: 767px) and (max-width: 991px) and (min-width: 768px) and (orientation: portrait) {
      .filter-button-wrapper .btn-filter-mobile {
        left: 40%; } }

@media screen and (max-width: 767px) {
      .filter-button-wrapper .btn-filter-mobile i {
        margin-right: 5px; } }

#hotels_filter {
  margin-bottom: 1em;
  border: solid 1px #d9d7d6; }
  @media screen and (max-width: 767px) {
    #hotels_filter {
      margin-bottom: 0;
      border: none; } }
  #hotels_filter .query {
    background-color: #d9d7d6;
    padding: 18px; }
    #hotels_filter .query .destination-menu .search-input {
      height: 22px;
      font-size: 1.14286em;
      padding: 4px 10px; }
    @media screen and (max-width: 767px) {
      #hotels_filter .query .destination-menu .search-input {
        font-size: 1.14286em;
        height: 36px;
        padding: 9px 10px 9px 18px;
        box-shadow: none;
        background-color: #fff; }
        #hotels_filter .query .destination-menu .search-input span {
          font-weight: 300;
          font-family: "Nunito Sans", sans-serif;
          color: #000; } }
    @media screen and (min-width: 768px) {
      #hotels_filter .query .destination-menu .search-input {
        padding: 8px 12px;
        height: 34px; }
        #hotels_filter .query .destination-menu .search-input span {
          font-size: 1em; } }
    @media screen and (min-width: 992px) {
      #hotels_filter .query .destination-menu .search-input {
        padding: 8px 16px; } }
    @media screen and (min-width: 768px) {
      #hotels_filter .query {
        padding-bottom: 32px; }
        #hotels_filter .query .destination.open .dropdown-menu.flyout-wrapper {
          width: 900px;
          top: -100px; } }
    #hotels_filter .query .hotel-datepicker-width-2-inputs {
      padding: 0 15px; }
      #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline {
        height: 22px; }
        #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker {
          height: 22px;
          font-size: 0.71429em; }
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker input {
            width: 110px;
            padding-left: 10px;
            padding-right: 10px;
            background: #fff;
            font-size: 1.1em;
            letter-spacing: -0.5px;
            background-image: none;
            color: #3462ae; }
            @media screen and (max-width: 1199px) and (min-width: 992px) and (orientation: landscape) {
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker input {
                width: 70px; } }
            @media screen and (max-width: 1024px) and (min-width: 1023px) and (orientation: portrait) {
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker input {
                width: 70px; } }
            @media screen and (min-width: 992px) {
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker input {
                padding-left: 16px; } }
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-go {
            font-size: 1em;
            width: 80px;
            padding-right: 0; }
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-live {
            width: 84px;
            font-size: 1em;
            background-position: 90% 45%;
            background-size: auto;
            background-repeat: no-repeat;
            padding-left: 2px; }
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-live__has-value {
            width: 100%; }
          @media screen and (max-width: 767px) {
            #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-go {
              padding-left: 18px; }
            #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-go,
            #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-live {
              font-size: 1.6em;
              font-weight: 300;
              color: #000; }
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-go::-webkit-input-placeholder,
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-live::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: #000; }
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-go:-moz-placeholder,
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-live:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: #000; }
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-go::-moz-placeholder,
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-live::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: #000; }
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-go:-ms-input-placeholder,
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-live:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #000; }
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-go::-ms-input-placeholder,
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-live::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #000; } }
          @media screen and (min-width: 768px) {
            #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker {
              font-size: 1em; }
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-go,
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .date-to-live {
                font-size: 1.14286em; }
              #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker .checkin-span {
                font-size: 16px; } }
        @media screen and (max-width: 767px) {
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker {
            height: 3.6em; }
            #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker:before {
              background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOSIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDE5IDIxIj4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSIjNjI1OTU2IiBkPSJNMS40NTUgOC4xMjVoMTYuMDljLjI1IDAgLjQ1NS4yLjQ1NS40NDV2OS42NDljMCAuOTgzLS44MTYgMS43ODEtMS44MjEgMS43ODFIMi44MkMxLjgxNiAyMCAxIDE5LjIwMiAxIDE4LjIxOVY4LjU3YzAtLjI0NS4yMDUtLjQ0NS40NTUtLjQ0NXpNMTggNi40OTJWNS4xNTZjMC0uOTgzLS44MTYtMS43ODEtMS44MjEtMS43ODFoLTEuODIydi0xLjkzQS40NTIuNDUyIDAgMCAwIDEzLjkwMiAxaC0xLjUxOGMtLjI1IDAtLjQ1NS4yLS40NTUuNDQ1djEuOTNINy4wN3YtMS45M0EuNDUyLjQ1MiAwIDAgMCA2LjYxNiAxSDUuMDk4Yy0uMjUgMC0uNDU1LjItLjQ1NS40NDV2MS45M0gyLjgyQzEuODE2IDMuMzc1IDEgNC4xNzMgMSA1LjE1NnYxLjMzNmMwIC4yNDUuMjA1LjQ0Ni40NTUuNDQ2aDE2LjA5Yy4yNSAwIC40NTUtLjIuNDU1LS40NDZ6Ii8+Cjwvc3ZnPgo=) center no-repeat;
              top: 8px;
              width: 17px;
              height: 21px; } }
        #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .search-input {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none; }
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .search-input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #3462ae; }
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .search-input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #3462ae; }
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .search-input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #3462ae; }
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .search-input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #3462ae; }
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group .search-input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #3462ae; }
        #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-control {
          height: 22px; }
          @media screen and (min-width: 768px) {
            #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-control {
              height: 34px; } }
          @media screen and (min-width: 992px) {
            #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-control {
              height: 34px; } }
        @media screen and (max-width: 767px) {
          #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-control {
            height: 2.4em; } }
      @media screen and (max-width: 767px) {
        #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline {
          height: 2.57143em; } }
      #hotels_filter .query .hotel-datepicker-width-2-inputs .check-in {
        position: relative; }
        #hotels_filter .query .hotel-datepicker-width-2-inputs .check-in:before {
          position: absolute;
          top: 1px;
          right: 6px;
          content: '-';
          display: block;
          width: 3px;
          height: 1px;
          color: #3462ae; }
          @media screen and (max-width: 767px) {
            #hotels_filter .query .hotel-datepicker-width-2-inputs .check-in:before {
              top: 8px;
              color: #000; } }
          @media screen and (min-width: 768px) {
            #hotels_filter .query .hotel-datepicker-width-2-inputs .check-in:before {
              top: 6px;
              right: 6px; } }
          @media screen and (min-width: 992px) {
            #hotels_filter .query .hotel-datepicker-width-2-inputs .check-in:before {
              right: 4px; } }
    #hotels_filter .query .date-range {
      margin-top: 1em; }
      #hotels_filter .query .date-range .no-check-in-check-out-error {
        margin-top: 25px;
        color: red; }
        #hotels_filter .query .date-range .no-check-in-check-out-error .fa-info-circle {
          margin-right: 4px; }
    #hotels_filter .query .check-out {
      width: 100%;
      float: right; }
      #hotels_filter .query .check-out:after {
        clear: both; }
      #hotels_filter .query .check-out .input-group-datepicker {
        position: relative; }
        #hotels_filter .query .check-out .input-group-datepicker:before {
          position: absolute;
          display: block;
          content: '';
          background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEzIDE2Ij4KICAgIDxwYXRoIGZpbGw9IiM2MjU5NTYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTQuMjk1IDlIMy4xMzRjLS4xOTIgMC0uMzQ4LS4xNjktLjM0OC0uMzc1di0xLjI1YzAtLjIwNi4xNTYtLjM3NS4zNDgtLjM3NWgxLjE2Yy4xOTIgMCAuMzQ5LjE2OS4zNDkuMzc1djEuMjVjMCAuMjA2LS4xNTcuMzc1LS4zNDguMzc1em0zLjEzNC0uMzc1di0xLjI1YzAtLjIwNi0uMTU3LS4zNzUtLjM0OS0uMzc1SDUuOTJjLS4xOTIgMC0uMzQ5LjE2OS0uMzQ5LjM3NXYxLjI1YzAgLjIwNi4xNTcuMzc1LjM0OS4zNzVoMS4xNmMuMTkyIDAgLjM0OS0uMTY5LjM0OS0uMzc1em0yLjc4NSAwdi0xLjI1YzAtLjIwNi0uMTU2LS4zNzUtLjM0OC0uMzc1aC0xLjE2Yy0uMTkyIDAtLjM0OS4xNjktLjM0OS4zNzV2MS4yNWMwIC4yMDYuMTU3LjM3NS4zNDguMzc1aDEuMTYxYy4xOTIgMCAuMzQ4LS4xNjkuMzQ4LS4zNzV6bS0yLjc4NSAzdi0xLjI1YzAtLjIwNi0uMTU3LS4zNzUtLjM0OS0uMzc1SDUuOTJjLS4xOTIgMC0uMzQ5LjE2OS0uMzQ5LjM3NXYxLjI1YzAgLjIwNi4xNTcuMzc1LjM0OS4zNzVoMS4xNmMuMTkyIDAgLjM0OS0uMTY5LjM0OS0uMzc1em0tMi43ODYgMHYtMS4yNWMwLS4yMDYtLjE1Ny0uMzc1LS4zNDgtLjM3NUgzLjEzNGMtLjE5MiAwLS4zNDguMTY5LS4zNDguMzc1djEuMjVjMCAuMjA2LjE1Ni4zNzUuMzQ4LjM3NWgxLjE2Yy4xOTIgMCAuMzQ5LS4xNjkuMzQ5LS4zNzV6bTUuNTcxIDB2LTEuMjVjMC0uMjA2LS4xNTYtLjM3NS0uMzQ4LS4zNzVoLTEuMTZjLS4xOTIgMC0uMzQ5LjE2OS0uMzQ5LjM3NXYxLjI1YzAgLjIwNi4xNTcuMzc1LjM0OC4zNzVoMS4xNjFjLjE5MiAwIC4zNDgtLjE2OS4zNDgtLjM3NXpNMTMgMy41djExYzAgLjgyOC0uNjI0IDEuNS0xLjM5MyAxLjVIMS4zOTNDLjYyMyAxNiAwIDE1LjMyOCAwIDE0LjV2LTExQzAgMi42NzIuNjI0IDIgMS4zOTMgMmgxLjM5M1YuMzc1YzAtLjIwNi4xNTYtLjM3NS4zNDgtLjM3NWgxLjE2Yy4xOTIgMCAuMzQ5LjE2OS4zNDkuMzc1VjJoMy43MTRWLjM3NWMwLS4yMDYuMTU3LS4zNzUuMzQ4LS4zNzVoMS4xNjFjLjE5MiAwIC4zNDguMTY5LjM0OC4zNzVWMmgxLjM5M0MxMi4zNzcgMiAxMyAyLjY3MiAxMyAzLjV6bS0xLjM5MyAxMC44MTNWNUgxLjM5M3Y5LjMxM2MwIC4xMDMuMDc4LjE4Ny4xNzQuMTg3aDkuODY2Yy4wOTYgMCAuMTc0LS4wODQuMTc0LS4xODh6Ii8+Cjwvc3ZnPgo=) center no-repeat;
          top: 5px;
          right: 13px;
          width: 9px;
          height: 11px;
          z-index: 2; }
          @media screen and (min-width: 768px) {
            #hotels_filter .query .check-out .input-group-datepicker:before {
              display: none;
              top: 8px;
              right: 15px;
              width: 13px;
              height: 16px; } }
          @media screen and (min-width: 992px) {
            #hotels_filter .query .check-out .input-group-datepicker:before {
              display: block;
              right: 19px; } }
    #hotels_filter .query .row-fluid .btn-group.select .btn span {
      color: #3462ae; }
    @media screen and (max-width: 767px) {
      #hotels_filter .query .date-range {
        margin-top: 10px; }
      #hotels_filter .query .row-fluid .btn-group.select .btn span {
        color: #000; } }
  @media screen and (max-width: 767px) {
    #hotels_filter .query {
      padding: 13px 34px; }
      #hotels_filter .query .hotel-datepicker-width-2-inputs {
        padding: 0; }
        #hotels_filter .query .hotel-datepicker-width-2-inputs .input-group-datepicker {
          font-size: 1.8em; }
        #hotels_filter .query .hotel-datepicker-width-2-inputs .checkin-span {
          font-size: 1.7em; }
        #hotels_filter .query .hotel-datepicker-width-2-inputs .form-inline .form-group + .form-group {
          margin-left: 0; }
      #hotels_filter .query .sort-wrapper {
        margin-top: 10px;
        justify-content: space-between;
        align-items: center; }
        #hotels_filter .query .sort-wrapper__title {
          font-size: 1.14286em;
          letter-spacing: 2.7px;
          text-transform: uppercase;
          padding: 0; }
        #hotels_filter .query .sort-wrapper .pricesort-wrapper {
          flex-grow: 1; }
          #hotels_filter .query .sort-wrapper .pricesort-wrapper .btn-default {
            height: 1.89474em; }
          #hotels_filter .query .sort-wrapper .pricesort-wrapper .pricesort {
            padding-left: 10px; }
            #hotels_filter .query .sort-wrapper .pricesort-wrapper .pricesort .btn span {
              font-size: 0.78947em;
              font-weight: 300;
              color: #000; }
            #hotels_filter .query .sort-wrapper .pricesort-wrapper .pricesort .dropdown-menu {
              width: calc(100% - 10px);
              left: 10px;
              z-index: 5; }
      #hotels_filter .query .destination-filter {
        z-index: 1000; } }
  #hotels_filter .sub-query {
    background-color: #fff;
    padding: 12px; }
    #hotels_filter .sub-query .filter {
      border-bottom: 1px solid #d9d7d6; }
    #hotels_filter .sub-query .hotel-types {
      margin-top: 14px; }
    #hotels_filter .sub-query .row-fluid {
      flex-direction: column; }
      #hotels_filter .sub-query .row-fluid .search-title {
        margin: 0; }
      #hotels_filter .sub-query .row-fluid > div + div:not(.room-count) {
        margin-top: 4px; }
        #hotels_filter .sub-query .row-fluid > div + div:not(.room-count).separator {
          margin-top: 28px; }
          #hotels_filter .sub-query .row-fluid > div + div:not(.room-count).separator div {
            border-top: 1px solid #000; }
        #hotels_filter .sub-query .row-fluid > div + div:not(.room-count).number-bed-room {
          margin-top: 0; }
        #hotels_filter .sub-query .row-fluid > div + div:not(.room-count).price-range-title {
          color: #473935; }
        #hotels_filter .sub-query .row-fluid > div + div:not(.room-count) .price-component-base {
          margin-top: 2px; }
          #hotels_filter .sub-query .row-fluid > div + div:not(.room-count) .price-component-base .time-unit {
            display: none; }
      #hotels_filter .sub-query .row-fluid > .area-filter {
        margin-top: 0; }
        #hotels_filter .sub-query .row-fluid > .area-filter > h4 {
          margin-top: 14px; }
      #hotels_filter .sub-query .row-fluid > .city-filter h4 {
        margin-top: 14px; }
      #hotels_filter .sub-query .row-fluid .number-bed-room {
        display: flex;
        align-items: center;
        padding-bottom: 4px;
        border-bottom: solid 1px #d9d7d6; }
        @media screen and (max-width: 767px) {
          #hotels_filter .sub-query .row-fluid .number-bed-room {
            margin-top: 0;
            padding: 4px 34px; } }
        #hotels_filter .sub-query .row-fluid .number-bed-room h4 {
          margin: 0; }
        #hotels_filter .sub-query .row-fluid .number-bed-room .room-count {
          margin-top: 0;
          height: 18px;
          font-size: 14px;
          width: 100%;
          border: solid 1px #d9d7d6; }
          #hotels_filter .sub-query .row-fluid .number-bed-room .room-count .search-input {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 6px;
            height: 15px;
            font-weight: 300; }
            #hotels_filter .sub-query .row-fluid .number-bed-room .room-count .search-input .bedroom-caret {
              top: 0;
              left: 3px;
              border-width: 4px; }
          @media screen and (min-width: 768px) {
            #hotels_filter .sub-query .row-fluid .number-bed-room .room-count {
              height: 24px; }
              #hotels_filter .sub-query .row-fluid .number-bed-room .room-count .search-input {
                height: 22px;
                font-size: 1em; }
                #hotels_filter .sub-query .row-fluid .number-bed-room .room-count .search-input .bedroom-caret {
                  border-width: 7px;
                  margin-left: 13px;
                  margin-right: 2px; } }
      @media screen and (max-width: 767px) {
        #hotels_filter .sub-query .row-fluid .number-bed-room {
          margin-top: 0;
          padding: 18px 34px; }
          #hotels_filter .sub-query .row-fluid .number-bed-room .room-count {
            margin-left: 0;
            width: 100%;
            height: auto; }
            #hotels_filter .sub-query .row-fluid .number-bed-room .room-count .bedrooms .search-input {
              font-size: 1.14286em;
              height: 2.57143em;
              padding: 0 12px 0 18px; }
              #hotels_filter .sub-query .row-fluid .number-bed-room .room-count .bedrooms .search-input span.caret {
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 8px solid #14a651;
                left: 0; } }
      #hotels_filter .sub-query .row-fluid .price-sort {
        margin-top: 5px !important; }
      #hotels_filter .sub-query .row-fluid .filter ul li {
        display: flex;
        align-items: center;
        padding: 5px 0; }
        #hotels_filter .sub-query .row-fluid .filter ul li .filter-hover:hover {
          background-color: #14a651; }
      #hotels_filter .sub-query .row-fluid .filter ul label:first-letter {
        text-transform: capitalize; }
      @media screen and (max-width: 767px) {
        #hotels_filter .sub-query .row-fluid .filter ul li {
          padding: 7px 0; }
        #hotels_filter .sub-query .row-fluid .price-range-title {
          padding-bottom: 10px;
          margin-top: 4px; } }
      #hotels_filter .sub-query .row-fluid .number-bed-room .dropdown-menu {
        min-width: 94px;
        overflow: hidden; }
        #hotels_filter .sub-query .row-fluid .number-bed-room .dropdown-menu li a {
          padding: 3px 18px;
          color: #000;
          width: 100%;
          font-weight: 300;
          font-size: 1.14286em; }
        @media screen and (max-width: 767px) {
          #hotels_filter .sub-query .row-fluid .number-bed-room .dropdown-menu {
            z-index: 5; } }
        @media screen and (min-width: 768px) {
          #hotels_filter .sub-query .row-fluid .number-bed-room .dropdown-menu {
            min-width: 120px; } }
    #hotels_filter .sub-query .rc-slider + .pull-left, #hotels_filter .sub-query .rc-slider + .pull-left + .pull-right {
      margin-top: 3px; }
    #hotels_filter .sub-query .rc-slider-handle {
      margin-left: -10px;
      margin-top: -5px;
      width: 23px;
      height: 14px;
      border: none;
      background-color: #14a651;
      border-radius: 0; }
    #hotels_filter .sub-query .rc-slider-handle-2 {
      margin-left: -15px; }
    @media screen and (max-width: 767px) {
      #hotels_filter .sub-query .rc-slider + .pull-left, #hotels_filter .sub-query .rc-slider + .pull-left + .pull-right {
        margin-top: 6px; }
      #hotels_filter .sub-query .rc-slider-handle {
        margin-left: -15px;
        margin-top: -5px;
        width: 2.14286em;
        height: 1.21429em; }
      #hotels_filter .sub-query .rc-slider-handle-2 {
        margin-left: -15px; } }
    #hotels_filter .sub-query .rc-slider-track,
    #hotels_filter .sub-query .rc-slider-step,
    #hotels_filter .sub-query .rc-slider-rail {
      background: #d9d7d6;
      top: 5px; }
    #hotels_filter .sub-query .price-range {
      font-size: 0.64286em;
      padding-left: 12px;
      padding-right: 12px;
      height: 43px; }
      #hotels_filter .sub-query .price-range .range-subtitle {
        margin: 0 -10px;
        font-weight: bold;
        letter-spacing: 1.5px; }
      #hotels_filter .sub-query .price-range .price-slider.rc-slider-disabled {
        background-color: unset; }
        #hotels_filter .sub-query .price-range .price-slider.rc-slider-disabled .rc-slider-handle-1,
        #hotels_filter .sub-query .price-range .price-slider.rc-slider-disabled .rc-slider-handle-2 {
          background-color: #d9d7d6; }
    @media screen and (max-width: 767px) {
      #hotels_filter .sub-query .rc-slider {
        padding: 10px 0; }
      #hotels_filter .sub-query .rc-slider-track,
      #hotels_filter .sub-query .rc-slider-step,
      #hotels_filter .sub-query .rc-slider-rail {
        top: 12px; }
      #hotels_filter .sub-query .price-range {
        font-size: 1em;
        padding: 0 49px;
        padding-top: 4px;
        border: none; }
        #hotels_filter .sub-query .price-range .range-subtitle {
          margin: 0 -15px;
          letter-spacing: 2.3px; } }
    @media screen and (min-width: 768px) {
      #hotels_filter .sub-query {
        padding: 16px 18px 12px; }
        #hotels_filter .sub-query .price-range {
          font-size: 0.78571em;
          padding-bottom: 50px;
          margin: 8px 0 12px 0;
          font-size: 0.92857em; }
          #hotels_filter .sub-query .price-range .range-subtitle {
            letter-spacing: 2.2px; } }
    #hotels_filter .sub-query ul {
      list-style: none; }
      #hotels_filter .sub-query ul li.currencies a {
        color: #000;
        background: #fff;
        padding: 6px 12px;
        display: inline-block;
        text-decoration: none; }
        #hotels_filter .sub-query ul li.currencies a:hover {
          text-decoration: none;
          color: #14a651; }
    #hotels_filter .sub-query .clear-filter {
      color: #000;
      text-decoration: underline; }
  @media screen and (max-width: 767px) {
    #hotels_filter .sub-query {
      padding: 0; }
      #hotels_filter .sub-query .row-fluid {
        flex-direction: column; }
        #hotels_filter .sub-query .row-fluid .promotion-types,
        #hotels_filter .sub-query .row-fluid .managed-by {
          margin-top: 0 !important; }
          #hotels_filter .sub-query .row-fluid .promotion-types__list-item,
          #hotels_filter .sub-query .row-fluid .managed-by__list-item {
            padding: 10px 34px; }
      #hotels_filter .sub-query .hotel-types {
        margin-top: 16px; } }
  @media screen and (min-width: 768px) {
    #hotels_filter .sub-query .row-fluid .hotel-types {
      order: 5; }
    #hotels_filter .sub-query .row-fluid .city-filter,
    #hotels_filter .sub-query .row-fluid .area-filter {
      order: 1; }
    #hotels_filter .sub-query .row-fluid .number-bed-room {
      order: 2;
      margin: 0;
      padding: 12px 0; }
    #hotels_filter .sub-query .row-fluid .lift-distance-types,
    #hotels_filter .sub-query .row-fluid .hiraku-distance-types {
      order: 3; }
    #hotels_filter .sub-query .row-fluid .price-range-title {
      order: 6; }
    #hotels_filter .sub-query .row-fluid .clear-filters {
      order: 7; }
    #hotels_filter .sub-query .row-fluid .managed-by,
    #hotels_filter .sub-query .row-fluid .promotion-types,
    #hotels_filter .sub-query .row-fluid .amenities-types,
    #hotels_filter .sub-query .row-fluid .services-types {
      order: 4; }
    #hotels_filter .sub-query .row-fluid .services-types {
      border: none; }
    #hotels_filter .sub-query .row-fluid .managed-by > ul, #hotels_filter .sub-query .row-fluid .promotion-types > ul {
      padding-top: 6px; } }
  #hotels_filter .filter-list-item {
    list-style: none;
    padding: 0 0 10px 0;
    margin-bottom: 0; }
    #hotels_filter .filter-list-item li input[type=checkbox] {
      display: none; }
      #hotels_filter .filter-list-item li input[type=checkbox] + label {
        cursor: pointer;
        color: #3462ae;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        padding-left: 20px; }
        #hotels_filter .filter-list-item li input[type=checkbox] + label:before {
          display: inline-block;
          content: "";
          color: #14a651;
          letter-spacing: 10px;
          cursor: pointer;
          font-size: 0.9em;
          left: 0;
          top: 1px;
          width: 12px;
          height: 12px;
          position: absolute;
          object-fit: cover;
          border: solid 1px #d9d7d6; }
        @media screen and (min-width: 768px) {
          #hotels_filter .filter-list-item li input[type=checkbox] + label {
            padding-left: 28px; }
            #hotels_filter .filter-list-item li input[type=checkbox] + label:before {
              width: 19px;
              height: 18px; } }
      #hotels_filter .filter-list-item li input[type=checkbox]:checked + label:before {
        width: 12px;
        height: 12px;
        top: 1px;
        border: none;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZmlsbD0iIzE0YTY1MSIgc3Ryb2tlPSIjMTRhNjUxIiBkPSJNLjUuNWgxMXYxMUguNXoiLz4KICAgICAgICA8cGF0aCBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTIgNi4xNTVMNC43MTMgOWw1LjIxMS03Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center; }
        @media screen and (min-width: 768px) {
          #hotels_filter .filter-list-item li input[type=checkbox]:checked + label:before {
            width: 19px;
            height: 18px;
            background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOSIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE5IDE4Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZmlsbD0iIzE0YTY1MSIgc3Ryb2tlPSIjMTRhNjUxIiBkPSJNLjUuNWgxOHYxN0guNXoiLz4KICAgICAgICA8cGF0aCBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTMgOC45MzZMNy40NSAxMyAxNiAzIi8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center; } }
    #hotels_filter .filter-list-item li input[type=checkbox] + label {
      color: #000; }
    #hotels_filter .filter-list-item li label {
      margin-bottom: 0; }
    #hotels_filter .filter-list-item .expand-link {
      display: inline-block;
      font-size: 0.71429em;
      color: #14a651;
      margin-top: 10px;
      margin-left: 28px;
      cursor: pointer;
      text-decoration: none; }
      #hotels_filter .filter-list-item .expand-link .caret {
        position: relative;
        top: 0;
        border-top-color: #14a651;
        border-width: 4px;
        margin-left: 8px; }
    @media screen and (min-width: 768px) {
      #hotels_filter .filter-list-item li input[type=checkbox] + label {
        font-size: 1em; }
      #hotels_filter .filter-list-item .expand-link {
        font-size: 1em; }
        #hotels_filter .filter-list-item .expand-link .caret {
          border-width: 7px; } }
  @media screen and (max-width: 767px) {
    #hotels_filter .filter-list-item {
      padding: 0 34px 10px 34px; }
      #hotels_filter .filter-list-item li input[type=checkbox] + label {
        font-size: 1.14286em;
        padding-left: 30px; }
        #hotels_filter .filter-list-item li input[type=checkbox] + label:before {
          font-size: 1em;
          top: 1px;
          width: 20px;
          height: 20px; }
      #hotels_filter .filter-list-item li input[type=checkbox]:checked + label:before {
        width: 20px;
        height: 20px;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZmlsbD0iIzE0YTY1MSIgc3Ryb2tlPSIjMTRhNjUxIiBkPSJNLjUuNWgxOXYxOUguNXoiLz4KICAgICAgICA8cGF0aCBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMyIgZD0iTTQgMTAuMTI0TDguNDUgMTUgMTcgMyIvPgogICAgPC9nPgo8L3N2Zz4K) no-repeat center; }
      #hotels_filter .filter-list-item .expand-link {
        font-size: 1.07143em;
        margin-left: 30px;
        display: inline-block;
        margin-top: 6px; }
        #hotels_filter .filter-list-item .expand-link .caret {
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 8px solid #14a651; }
      #hotels_filter .filter-list-item .dropup .caret {
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 8px solid #14a651;
        border-top: 0; } }
  #hotels_filter .services-types__list-item {
    border: none; }
  #hotels_filter h4 {
    margin-bottom: 5px;
    font-size: 1em; }
  @media screen and (max-width: 767px) {
    #hotels_filter h4 {
      font-size: 1.14286em;
      letter-spacing: 2.7px;
      text-transform: uppercase;
      padding: 0 34px;
      margin-top: 15px; } }
  @media screen and (min-width: 768px) {
    #hotels_filter h4 {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 1.14286em;
      font-weight: 300; } }
  #hotels_filter .row-fluid .btn-group.select .btn span {
    font-size: 1em; }
  #hotels_filter .row-fluid .btn-group.select .btn span.caret {
    top: 3px; }
  @media screen and (max-width: 767px) {
    #hotels_filter .row-fluid .btn-group.select .btn span {
      font-size: 1em;
      color: #000; }
      #hotels_filter .row-fluid .btn-group.select .btn span.caret {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 8px solid #14a651;
        top: 5px; } }
  @media screen and (min-width: 768px) {
    #hotels_filter .row-fluid .btn-group.select .btn span {
      font-size: 1em; }
      #hotels_filter .row-fluid .btn-group.select .btn span.caret {
        top: 5px;
        border-width: 10px; } }
  #hotels_filter .price {
    color: #000;
    font-weight: normal;
    margin: 0px -10px; }
  @media screen and (max-width: 767px) {
    #hotels_filter .price {
      margin: 0px -15px; } }
  #hotels_filter .search-title {
    font-size: 0.92857em;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    margin: 0px 0 13px; }
  @media screen and (min-width: 768px) {
    #hotels_filter .search-title {
      font-size: 1.14286em; }
      #hotels_filter .search-title--query {
        color: #3462ae; } }
  #hotels_filter .disable-link {
    pointer-events: none;
    color: #d9d7d6; }
