/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
#that-villa-life h2 {
  font-size: 2em; }

#that-villa-life .carousel .instagram-photo img {
  min-width: 100%;
  height: 305px; }

#that-villa-life .carousel .instagram-photo:first-child {
  padding-right: 0; }

#that-villa-life .carousel .instagram-photo:last-child {
  padding-left: 0; }

#that-villa-life .carousel .carousel-control.left span.glyphicon, #that-villa-life .carousel .carousel-control.right span.glyphicon {
  width: 40px;
  height: 40px;
  top: 43%; }

#that-villa-life .carousel .carousel-control.right span.glyphicon {
  margin-left: -10px; }
