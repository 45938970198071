/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
/* =============================================================
 * BASE STYLES
 * ============================================================*/
.datepicker {
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  width: 260px;
  z-index: 1; }
  @media (min-width: 320px) {
    .datepicker {
      width: 300px; } }
  @media (min-width: 480px) {
    .datepicker {
      width: 460px; } }
  @media (min-width: 768px) {
    .datepicker {
      width: 560px; } }

.datepicker__inner {
  overflow: hidden; }

@media (min-width: 480px) {
  .datepicker__months {
    overflow: hidden; } }

.datepicker__month {
  border-collapse: collapse;
  text-align: center;
  width: 100%; }
  @media (min-width: 480px) {
    .datepicker__month {
      width: 200px; } }
  @media (min-width: 768px) {
    .datepicker__month {
      width: 240px; } }

@media (min-width: 480px) {
  .datepicker__month--month1 {
    float: left; } }

.datepicker__month--month2 {
  display: none; }
  @media (min-width: 480px) {
    .datepicker__month--month2 {
      display: table;
      float: right; } }

.datepicker__month-day--valid {
  cursor: pointer; }

.datepicker__month-day--lastMonth,
.datepicker__month-day--nextMonth {
  visibility: hidden; }

.datepicker__month-button {
  cursor: pointer; }

@media (min-width: 480px) {
  .datepicker__month-button--disabled {
    visibility: hidden; } }

.datepicker__info--feedback {
  display: none; }

.datepicker__info--error,
.datepicker__info--help {
  display: block; }

.datepicker__close-button {
  cursor: pointer; }

.datepicker__tooltip {
  position: absolute; }

/* =============================================================
 * THEME
 * ============================================================*/
.datepicker {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 8px 8px 40px 5px rgba(0, 0, 0, 0.08);
  color: #000;
  font-family: 'Helvetica', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 14px;
  line-height: 14px; }

.datepicker__inner {
  padding: 20px; }

.datepicker__month {
  font-size: 12px; }

@media (min-width: 480px) {
  .datepicker__months {
    position: relative; }
    .datepicker__months:before {
      background: rgba(136, 136, 136, 0.35);
      bottom: 0;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      top: 0;
      width: 1px; } }

.datepicker__month-caption {
  border-bottom: 1px solid rgba(136, 136, 136, 0.35);
  height: 2.5em;
  vertical-align: middle; }

.datepicker__month-name {
  text-transform: uppercase; }

.datepicker__week-days {
  height: 2em;
  vertical-align: middle; }

.datepicker__week-name {
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase; }

.datepicker__month-day {
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: #000;
  padding: 9px 7px; }

.datepicker__month-day--no-check-in {
  position: relative; }
  .datepicker__month-day--no-check-in:after {
    background-color: rgba(255, 0, 0, 0.1);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 50%;
    top: 0;
    z-index: -1; }

.datepicker__month-day--no-check-out {
  position: relative; }
  .datepicker__month-day--no-check-out:after {
    background-color: rgba(255, 0, 0, 0.1);
    bottom: 0;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1; }

.datepicker__month-day--invalid {
  color: #e8ebf4; }

.datepicker__month-day--disabled {
  color: #e8ebf4;
  position: relative; }
  .datepicker__month-day--disabled:after {
    content: '\D7';
    left: 50%;
    position: absolute;
    color: red;
    font-size: 16px;
    top: 50%;
    transform: translate(-50%, -50%); }

.datepicker__month-day--day-of-week-disabled {
  background-color: rgba(232, 235, 244, 0.5); }

.datepicker__month-day--selected {
  background-color: rgba(20, 166, 81, 0.2);
  color: #fff; }
  .datepicker__month-day--selected:after {
    display: none; }

.datepicker__month-day--hovering {
  background-color: rgba(20, 166, 81, 0.3);
  color: #fff; }

.datepicker__month-day--today {
  background-color: #000;
  color: #fff; }

.datepicker__month-day--first-day-selected,
.datepicker__month-day--last-day-selected {
  background-color: #14a651;
  color: #fff; }

.datepicker__month-day--last-day-selected:after {
  content: none; }

.datepicker__month-button {
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(20, 166, 81, 0.7);
  border-radius: 4px;
  color: #9da6b8;
  display: inline-block;
  padding: 5px 10px; }
  .datepicker__month-button:hover {
    background-color: #14a651;
    color: #fff; }

.datepicker__topbar {
  margin-bottom: 20px;
  position: relative; }

.datepicker__info-text {
  font-size: 13px; }

.datepicker__info--selected {
  font-size: 11px;
  text-transform: uppercase; }

.datepicker__info--selected-label {
  color: #000; }

.datepicker__info-text--selected-days {
  font-size: 11px;
  font-style: normal; }

.datepicker__info--error {
  color: red;
  font-size: 13px;
  font-style: italic; }

.datepicker__info--help {
  color: #000;
  font-style: italic; }

.datepicker__close-button {
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #14a651;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  font-size: 10px;
  color: #fff;
  margin-top: 20px;
  padding: 7px 13px;
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase; }
  .datepicker__close-button:hover {
    background-color: #000;
    color: #fff; }
  @media (min-width: 768px) {
    .datepicker__close-button {
      margin-top: 0;
      position: absolute;
      right: 0;
      top: 0; } }

.datepicker__tooltip {
  background-color: #ffe684;
  border-radius: 2px;
  font-size: 11px;
  margin-top: -5px;
  padding: 5px 10px; }
  .datepicker__tooltip:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #ffe684;
    bottom: -4px;
    content: '';
    left: 50%;
    margin-left: -4px;
    position: absolute; }

.hotel-datepicker-width-2-inputs {
  cursor: pointer; }
  .hotel-datepicker-width-2-inputs > div {
    position: relative; }
  .hotel-datepicker-width-2-inputs .form-inline {
    position: relative;
    display: flex; }
  @media screen and (max-width: 767px) {
    .hotel-datepicker-width-2-inputs .form-inline .form-group {
      flex: 1 1 auto; }
      .hotel-datepicker-width-2-inputs .form-inline .form-group + .form-group {
        margin-left: 0; }
      .hotel-datepicker-width-2-inputs .form-inline .form-group .input-group-datepicker input.form-control {
        width: 100% !important; } }
  @media screen and (min-width: 768px) {
    .hotel-datepicker-width-2-inputs .form-inline {
      height: 30px;
      background-color: #fff; } }
  .hotel-datepicker-width-2-inputs .datepicker {
    border-radius: 0; }
    .hotel-datepicker-width-2-inputs .datepicker .datepicker__topbar {
      display: none; }
    .hotel-datepicker-width-2-inputs .datepicker .datepicker__info--selected {
      display: none; }
    .hotel-datepicker-width-2-inputs .datepicker .datepicker__tooltip {
      background-color: rgba(20, 166, 81, 0.85);
      color: #fff; }
    .hotel-datepicker-width-2-inputs .datepicker .datepicker__month-button {
      color: #14a651;
      background-color: transparent; }
      .hotel-datepicker-width-2-inputs .datepicker .datepicker__month-button:hover {
        color: #14a651;
        background-color: transparent; }
    .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month {
      border: none;
      margin-top: inherit;
      margin-right: 1px; }
      .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month thead {
        background-color: transparent; }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month thead .datepicker__month-name {
          text-align: center;
          text-transform: none; }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month thead tr.datepicker__month-caption {
          border-bottom: none; }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month thead .datepicker__month-button {
          color: #000;
          transition-duration: 0.2s;
          transition-property: color, background-color, border-color;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
          .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month thead .datepicker__month-button:hover {
            color: #14a651; }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month thead th.datepicker__week-name {
          text-transform: none;
          font-weight: 600;
          text-align: center; }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month thead .datepicker__month-name, .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month thead th.datepicker__week-name {
          font-size: 1.16667em; }
      .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day--today {
        background-color: transparent;
        color: #000; }
      .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day {
        padding: 0;
        margin: 0;
        border: solid 1px rgba(136, 136, 136, 0.35); }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day span {
          display: block;
          padding: 9px 7px;
          font-size: 1.16667em; }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--no-check-in {
          background: linear-gradient(90deg, #fff 50%, #ccc 50%); }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--no-check-out {
          background: linear-gradient(90deg, #ccc 50%, #fff 50%); }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--selected {
          background: rgba(20, 166, 81, 0.6); }
          .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--selected.datepicker__month-day--first-day-selected, .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--selected.datepicker__month-day--last-day-selected {
            background: #14a651; }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--hovering {
          background: rgba(20, 166, 81, 0.55); }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--lastMonth, .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--nextMonth {
          visibility: visible;
          border: none;
          background-color: transparent !important; }
          .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--lastMonth span.hotel-datepicker__day, .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--nextMonth span.hotel-datepicker__day {
            display: none;
            cursor: default; }
          .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--lastMonth.datepicker__month-day--disabled:after, .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--nextMonth.datepicker__month-day--disabled:after {
            display: none; }
        .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--disabled {
          background: #f2f2f2;
          text-decoration: none;
          color: #d6d6d6; }
          .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--disabled span {
            cursor: not-allowed; }
          .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day.datepicker__month-day--disabled:after {
            display: none; }
      .hotel-datepicker-width-2-inputs .datepicker table.datepicker__month .datepicker__month-day--invalid span.hotel-datepicker__day {
        cursor: not-allowed; }
    @media screen and (max-width: 767px) {
      .hotel-datepicker-width-2-inputs .datepicker {
        width: 100%; } }
  .hotel-datepicker-width-2-inputs .input-group-datepicker input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #000; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #000;
    opacity: 1; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #000;
    opacity: 1; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #000; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker input:hover, .hotel-datepicker-width-2-inputs .input-group-datepicker input:active, .hotel-datepicker-width-2-inputs .input-group-datepicker input:focus {
    background-color: transparent; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker.text-danger input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #C71C22; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker.text-danger input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #C71C22;
    opacity: 1; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker.text-danger input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #C71C22;
    opacity: 1; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker.text-danger input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #C71C22; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker.text-danger input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #C71C22; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker.text-danger input:hover, .hotel-datepicker-width-2-inputs .input-group-datepicker.text-danger input:active, .hotel-datepicker-width-2-inputs .input-group-datepicker.text-danger input:focus {
    background-color: transparent; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker.text-danger input {
    border-color: #C71C22; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker input {
    width: 140px;
    border-radius: 0;
    padding-left: 10%;
    padding-right: 5px;
    background-size: 20% 80%;
    background-position-x: 3%; }
  .hotel-datepicker-width-2-inputs .input-group-datepicker .checkin-span {
    display: inline-block;
    visibility: hidden; }
  .hotel-datepicker-width-2-inputs .datepicker-one-line-over {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 20; }
    .hotel-datepicker-width-2-inputs .datepicker-one-line-over, .hotel-datepicker-width-2-inputs .datepicker-one-line-over.hotel-datepicker input {
      width: 100%;
      height: 100%; }
    .hotel-datepicker-width-2-inputs .datepicker-one-line-over.hotel-datepicker input {
      opacity: 0;
      border-radius: 0;
      border: 0;
      zoom: 1;
      cursor: pointer; }
      @media screen and (max-width: 767px) {
        .hotel-datepicker-width-2-inputs .datepicker-one-line-over.hotel-datepicker input {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          caret-color: transparent; } }
  .hotel-datepicker-width-2-inputs .short-datepicker {
    background-color: #fff; }
    .hotel-datepicker-width-2-inputs .short-datepicker:before {
      position: absolute;
      display: block;
      content: '';
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEzIDE2Ij4KICAgIDxwYXRoIGZpbGw9IiM2MjU5NTYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTQuMjk1IDlIMy4xMzRjLS4xOTIgMC0uMzQ4LS4xNjktLjM0OC0uMzc1di0xLjI1YzAtLjIwNi4xNTYtLjM3NS4zNDgtLjM3NWgxLjE2Yy4xOTIgMCAuMzQ5LjE2OS4zNDkuMzc1djEuMjVjMCAuMjA2LS4xNTcuMzc1LS4zNDguMzc1em0zLjEzNC0uMzc1di0xLjI1YzAtLjIwNi0uMTU3LS4zNzUtLjM0OS0uMzc1SDUuOTJjLS4xOTIgMC0uMzQ5LjE2OS0uMzQ5LjM3NXYxLjI1YzAgLjIwNi4xNTcuMzc1LjM0OS4zNzVoMS4xNmMuMTkyIDAgLjM0OS0uMTY5LjM0OS0uMzc1em0yLjc4NSAwdi0xLjI1YzAtLjIwNi0uMTU2LS4zNzUtLjM0OC0uMzc1aC0xLjE2Yy0uMTkyIDAtLjM0OS4xNjktLjM0OS4zNzV2MS4yNWMwIC4yMDYuMTU3LjM3NS4zNDguMzc1aDEuMTYxYy4xOTIgMCAuMzQ4LS4xNjkuMzQ4LS4zNzV6bS0yLjc4NSAzdi0xLjI1YzAtLjIwNi0uMTU3LS4zNzUtLjM0OS0uMzc1SDUuOTJjLS4xOTIgMC0uMzQ5LjE2OS0uMzQ5LjM3NXYxLjI1YzAgLjIwNi4xNTcuMzc1LjM0OS4zNzVoMS4xNmMuMTkyIDAgLjM0OS0uMTY5LjM0OS0uMzc1em0tMi43ODYgMHYtMS4yNWMwLS4yMDYtLjE1Ny0uMzc1LS4zNDgtLjM3NUgzLjEzNGMtLjE5MiAwLS4zNDguMTY5LS4zNDguMzc1djEuMjVjMCAuMjA2LjE1Ni4zNzUuMzQ4LjM3NWgxLjE2Yy4xOTIgMCAuMzQ5LS4xNjkuMzQ5LS4zNzV6bTUuNTcxIDB2LTEuMjVjMC0uMjA2LS4xNTYtLjM3NS0uMzQ4LS4zNzVoLTEuMTZjLS4xOTIgMC0uMzQ5LjE2OS0uMzQ5LjM3NXYxLjI1YzAgLjIwNi4xNTcuMzc1LjM0OC4zNzVoMS4xNjFjLjE5MiAwIC4zNDgtLjE2OS4zNDgtLjM3NXpNMTMgMy41djExYzAgLjgyOC0uNjI0IDEuNS0xLjM5MyAxLjVIMS4zOTNDLjYyMyAxNiAwIDE1LjMyOCAwIDE0LjV2LTExQzAgMi42NzIuNjI0IDIgMS4zOTMgMmgxLjM5M1YuMzc1YzAtLjIwNi4xNTYtLjM3NS4zNDgtLjM3NWgxLjE2Yy4xOTIgMCAuMzQ5LjE2OS4zNDkuMzc1VjJoMy43MTRWLjM3NWMwLS4yMDYuMTU3LS4zNzUuMzQ4LS4zNzVoMS4xNjFjLjE5MiAwIC4zNDguMTY5LjM0OC4zNzVWMmgxLjM5M0MxMi4zNzcgMiAxMyAyLjY3MiAxMyAzLjV6bS0xLjM5MyAxMC44MTNWNUgxLjM5M3Y5LjMxM2MwIC4xMDMuMDc4LjE4Ny4xNzQuMTg3aDkuODY2Yy4wOTYgMCAuMTc0LS4wODQuMTc0LS4xODh6Ii8+Cjwvc3ZnPgo=) center no-repeat;
      top: 8px;
      right: 22px;
      width: 13px;
      height: 16px;
      z-index: 2; }
    .hotel-datepicker-width-2-inputs .short-datepicker .input-group-datepicker {
      width: 50%; }
    .hotel-datepicker-width-2-inputs .short-datepicker__group {
      width: 100%; }
    .hotel-datepicker-width-2-inputs .short-datepicker .input-group-datepicker:first-child input.form-control {
      padding-left: 15px;
      padding-right: 0; }
    .hotel-datepicker-width-2-inputs .short-datepicker span {
      font-size: 1.07143em;
      padding-left: 0;
      line-height: 2.26667em; }
    .hotel-datepicker-width-2-inputs .short-datepicker .input-group-datepicker input.date-to-live:not(.textbox) {
      padding-left: 6px;
      padding-right: 0; }
