/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.default-color {
  color: #473935; }

.rates-table {
  padding-right: 0; }

.room-info--mobile {
  font-size: 1.07143em; }

.room-info__mobile-name {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.33;
  margin-bottom: 0;
  margin-top: 5px; }

.room-info__mobile-services {
  font-size: 0.66667em;
  font-weight: 300;
  font-style: italic;
  line-height: 1.5;
  margin-bottom: 2px; }

.room-info .room-carousel {
  width: calc(130px * 0.7);
  height: calc(75px * 0.7); }

.room-info__basic-info .carousel .carousel-control {
  background-image: none; }
  .room-info__basic-info .carousel .carousel-control span {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    width: 8px;
    height: 8px;
    margin: 0;
    top: 50%; }
  .room-info__basic-info .carousel .carousel-control.left {
    left: 10px; }
    .room-info__basic-info .carousel .carousel-control.left span {
      -moz-transform: translateX(0) translateY(-50%) rotate(50deg) skew(16deg, 5deg);
      -ms-transform: translateX(0) translateY(-50%) rotate(50deg) skew(16deg, 5deg);
      -o-transform: translateX(0) translateY(-50%) rotate(50deg) skew(16deg, 5deg);
      -webkit-transform: translateX(0) translateY(-50%) rotate(50deg) skew(16deg, 5deg);
      transform: translateX(0) translateY(-50%) rotate(50deg) skew(16deg, 5deg);
      left: 0; }
  .room-info__basic-info .carousel .carousel-control.right {
    right: 10px; }
    .room-info__basic-info .carousel .carousel-control.right span {
      -moz-transform: translateX(-100%) translateY(-50%) rotate(231deg) skew(16deg, 5deg);
      -ms-transform: translateX(-100%) translateY(-50%) rotate(231deg) skew(16deg, 5deg);
      -o-transform: translateX(-100%) translateY(-50%) rotate(231deg) skew(16deg, 5deg);
      -webkit-transform: translateX(-100%) translateY(-50%) rotate(231deg) skew(16deg, 5deg);
      transform: translateX(-100%) translateY(-50%) rotate(231deg) skew(16deg, 5deg);
      left: 100%; }

.room-info__basic-info .popover-content {
  padding: 0; }
  .room-info__basic-info .popover-content .carousel .carousel-indicators li {
    background-color: #14a651; }
  .room-info__basic-info .popover-content .carousel .carousel-inner > .item {
    background: #fff;
    padding: 4px 8px; }
    .room-info__basic-info .popover-content .carousel .carousel-inner > .item img {
      width: 100%;
      max-height: 415px; }
    .room-info__basic-info .popover-content .carousel .carousel-inner > .item .img-floor-plan {
      width: 100%;
      max-height: none; }
  .room-info__basic-info .popover-content .carousel .carousel-control.right {
    margin-right: 6px; }
  .room-info__basic-info .popover-content .carousel .carousel-control.left {
    margin-left: 6px; }
  .room-info__basic-info .popover-content .carousel .carousel-control span {
    width: 50px;
    height: 50px;
    border-bottom: 4px solid #14a651;
    border-left: 4px solid #14a651; }
    @media screen and (min-width: 992px) {
      .room-info__basic-info .popover-content .carousel .carousel-control span {
        width: 76px;
        height: 76px; } }

.room-info__services {
  margin-top: 10px; }
  .room-info__services__wrap {
    align-items: center;
    flex-wrap: wrap; }
  .room-info__services .room-bedroom-types {
    padding-left: 30px;
    font-size: 1em;
    color: #473935; }
    .room-info__services .room-bedroom-types li:not(:last-child) {
      margin-bottom: 5px; }

.room-info .carousel-inner > .item img {
  max-width: none; }

#hotel_detail_page .rates .rate-table__old-price .rate-table__old-price-wrap {
  position: relative;
  padding-left: 5px;
  padding-right: 5px; }
  #hotel_detail_page .rates .rate-table__old-price .rate-table__old-price-wrap:after {
    -moz-transform: rotate(175deg) skew(16deg, 5deg);
    -ms-transform: rotate(175deg) skew(16deg, 5deg);
    -o-transform: rotate(175deg) skew(16deg, 5deg);
    -webkit-transform: rotate(175deg) skew(16deg, 5deg);
    transform: rotate(175deg) skew(16deg, 5deg);
    position: absolute;
    content: "";
    top: 42%;
    right: 0;
    height: 1px;
    background-color: #9b9b9b;
    width: 100%; }

#hotel_detail_page .rates .rate-table__old-price--no-promotion .rate-table__old-price-wrap:after {
  display: none; }

#hotel_detail_page .rates .rate-table__old-price .price-component-base .old-price {
  color: #9b9b9b; }

#hotel_detail_page .rates .rate-table__old-price .rate-max {
  display: none; }

#hotel_detail_page .popover-wrapper {
  position: relative; }

@media screen and (max-width: 767px) {
  #hotel_detail_page .rates .rate-table__old-price .rate-table__old-price-wrap {
    padding-right: 0; }
    #hotel_detail_page .rates .rate-table__old-price .rate-table__old-price-wrap:after {
      -moz-transform: rotate(167deg) skew(16deg, 5deg);
      -ms-transform: rotate(167deg) skew(16deg, 5deg);
      -o-transform: rotate(167deg) skew(16deg, 5deg);
      -webkit-transform: rotate(167deg) skew(16deg, 5deg);
      transform: rotate(167deg) skew(16deg, 5deg); }
  #hotel_detail_page .rates .rate-table__old-price .price-component-base .old-price {
    line-height: 1; } }

@media screen and (min-width: 768px) {
  .rates-table__time-unit-text {
    margin-bottom: 2px;
    color: #473935;
    line-height: 1.8; }
  .room-info__name {
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.7px;
    margin: 0 0 5px; }
  .room-info__name, .room-info__more-info-content, .room-info__more-nested {
    font-size: 0.85714em; }
  .room-info__floor-plan-link, .room-info__more-nested {
    cursor: pointer; }
  .room-info__more-info-content {
    line-height: 1.64; }
    .room-info__more-info-content span {
      font-weight: bold;
      padding-right: 3px; }
  .room-info__more-btn {
    display: flex; }
  .room-info__more-btn-icon {
    width: 9px;
    height: 9px;
    margin-right: 3px; }
  .room-info__more-nested {
    font-weight: 600;
    line-height: 1.36;
    color: #14a651;
    text-decoration: underline; }
  .room-info__floor-plan {
    margin-left: 0; }
  .room-info__service {
    margin-right: 5px;
    margin-bottom: 5px;
    justify-content: center; }
    .room-info__service__detail {
      font-size: 0.85714em;
      margin-bottom: 0;
      margin-left: 3px;
      line-height: 1.36; }
    .room-info__service img {
      max-height: 11px; }
  .room-info__more-btn, .room-info__service {
    align-items: center; } }

@media screen and (min-width: 992px) {
  .room-info__name, .room-info__more-info-content, .room-info__more-nested {
    font-size: 1em; }
  .room-info__name {
    letter-spacing: 1px;
    margin-top: 5px;
    margin-bottom: 11px; }
  .room-info__more-info-content {
    margin-bottom: 2px;
    line-height: 1.71; }
  .room-info__more-btn-icon {
    width: 13px;
    height: 14px;
    margin-right: 5px; }
  .room-info .room-carousel {
    width: calc(215px * 0.7);
    height: calc(123px * 0.7); }
  .room-info__basic-info .carousel .carousel-control span {
    width: 12px;
    height: 12px; }
  .room-info__basic-info .carousel .carousel-control.left {
    left: 13px; }
  .room-info__basic-info .carousel .carousel-control.right {
    right: 13px; }
  .room-info__service {
    margin-right: 10px; }
    .room-info__service__detail {
      font-size: 1em;
      margin-left: 5px;
      padding-top: 2px; }
    .room-info__service:last-child {
      margin-right: 0; }
    .room-info__service img {
      max-height: 14px; }
  .room-info__more-nested, .room-info__service__detail {
    line-height: 1.07; } }

@media screen and (min-width: 1200px) {
  .room-info .room-carousel {
    width: calc(268px * 0.7);
    height: calc(152px * 0.7); }
  .room-info__basic-info .carousel .carousel-control span {
    width: 14px;
    height: 14px; }
  .room-info__basic-info .carousel .carousel-control.left {
    left: 16px; }
  .room-info__basic-info .carousel .carousel-control.right {
    right: 16px; } }

.room-more-info {
  padding-left: 30px;
  font-size: 1em;
  color: #473935; }
  .room-more-info li:not(:last-child) {
    margin-bottom: 5px; }

.more-info {
  font-size: 1em;
  line-height: 1 !important; }
