/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
section #homepage_newsletter h2 {
  font-size: 2em; }

section #homepage_newsletter form#subscribe_form {
  background: url(https://storage.googleapis.com/luxuryvillasandhomes.appspot.com/lvh_static/webpack_bundles/4d9c5a8d2c4fae0683bf361cde05845a.jpg) left center no-repeat;
  background-size: auto 100%;
  padding: 0; }
  section #homepage_newsletter form#subscribe_form h3 {
    color: #615956;
    font-family: "Libre Baskerville", serif;
    text-align: center;
    margin-bottom: 0; }
    @media screen and (max-width: 767px) {
      section #homepage_newsletter form#subscribe_form h3 {
        margin-top: 0;
        padding: 0 20px;
        font-size: 2.14286em; } }
  section #homepage_newsletter form#subscribe_form h4.sub-title {
    font-size: 1.14286em;
    letter-spacing: 2.7px;
    margin: 12px 0;
    line-height: 1.4;
    padding: 0 48px; }
  section #homepage_newsletter form#subscribe_form h4.mobile-sub-title {
    color: #3462ae;
    text-align: center;
    padding: 0 20px; }
  section #homepage_newsletter form#subscribe_form .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 32px;
    background-color: #fcf5ee; }
    @media screen and (max-width: 767px) {
      section #homepage_newsletter form#subscribe_form .form-container {
        padding-top: 38px;
        padding-bottom: 33px; } }
    section #homepage_newsletter form#subscribe_form .form-container .input-wrapper {
      width: 245px; }
      section #homepage_newsletter form#subscribe_form .form-container .input-wrapper .checkbox {
        margin-top: 1px; }
      section #homepage_newsletter form#subscribe_form .form-container .input-wrapper .form-group {
        margin-bottom: 10px; }
      section #homepage_newsletter form#subscribe_form .form-container .input-wrapper input.form-control {
        height: 31px;
        padding: 0 10px;
        font-size: 1.07143em;
        border-radius: 0;
        border: solid 1px #979797;
        color: #473935; }
        section #homepage_newsletter form#subscribe_form .form-container .input-wrapper input.form-control::placeholder {
          color: #473935; }
      @media screen and (max-width: 767px) {
        section #homepage_newsletter form#subscribe_form .form-container .input-wrapper input.form-control {
          font-size: 1.35714em;
          height: 2.31579em;
          padding-left: 30px;
          box-shadow: none; }
        section #homepage_newsletter form#subscribe_form .form-container .input-wrapper .checkbox > label {
          font-size: 0.78571em; } }
  section #homepage_newsletter form#subscribe_form .newsletter-submit {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.6px;
    width: 19.14286em;
    padding-top: 6px;
    padding-bottom: 6px; }
    section #homepage_newsletter form#subscribe_form .newsletter-submit:focus {
      outline: none; }
  @media screen and (max-width: 767px) {
    section #homepage_newsletter form#subscribe_form .newsletter-submit {
      width: 15.625em;
      font-size: 1.14286em;
      padding-top: 7px;
      padding-bottom: 7px;
      margin-top: 7px; } }

@media screen and (min-width: 768px) {
  section #homepage_newsletter form#subscribe_form h3 {
    font-size: 1.57143em; }
  section #homepage_newsletter form#subscribe_form .form-container .input-wrapper {
    width: 268px;
    margin-bottom: 0; }
    section #homepage_newsletter form#subscribe_form .form-container .input-wrapper .checkbox--large .newsletter-label {
      margin-bottom: 0; }
  section #homepage_newsletter form#subscribe_form h4.sub-title {
    margin: 6px 0 17px;
    padding: 0; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    section #homepage_newsletter form#subscribe_form h4.sub-title {
      font-size: 1em;
      letter-spacing: 2.3px; } }

@media screen and (min-width: 992px) {
  section #homepage_newsletter form#subscribe_form h3 {
    font-size: 2.14286em; }
  section #homepage_newsletter form#subscribe_form h4.sub-title {
    margin-top: 14px; } }

@media screen and (min-width: 1200px) {
  section #homepage_newsletter form#subscribe_form h4.sub-title {
    margin-bottom: 18px; } }
