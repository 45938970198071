/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
#__lpform_name_icon {
  opacity: 0 !important; }

#get_in_touch_form h2 {
  padding-left: 0;
  font-size: 2.14286em;
  margin-top: 0;
  text-align: center;
  font-family: "Libre Baskerville", serif; }
  @media screen and (max-width: 767px) {
    #get_in_touch_form h2 {
      padding: 0;
      font-size: 1.92857em; } }
  @media screen and (min-width: 992px) {
    #get_in_touch_form h2 {
      padding-left: 8px; } }

#get_in_touch_form button {
  margin-top: 0;
  min-width: 136px;
  font-weight: 600;
  font-size: 1em;
  padding: 5px 12px;
  border: solid 1px #fff;
  letter-spacing: 1.6px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    #get_in_touch_form button {
      margin-top: 0;
      font-size: 1.14286em;
      letter-spacing: 1.9px;
      min-width: 245px;
      padding: 8px 12px; } }
  @media screen and (min-width: 320px) and (max-width: 375px) {
    #get_in_touch_form button {
      min-width: 190px; } }

#get_in_touch_form form {
  padding: 0 4px; }
  #get_in_touch_form form .form-group {
    margin-bottom: 10px; }
    #get_in_touch_form form .form-group .form-control, #get_in_touch_form form .form-group .form-control::placeholder {
      color: #473935; }
    #get_in_touch_form form .form-group .form-control {
      height: 31px;
      border-radius: 0;
      font-size: 1.07143em;
      border-color: #979797; }
      @media screen and (max-width: 767px) {
        #get_in_touch_form form .form-group .form-control {
          height: 44px;
          font-size: 1.35714em;
          padding-right: 24px;
          padding-left: 24px; } }
    #get_in_touch_form form .form-group .description-area {
      height: 92px; }
      @media screen and (max-width: 767px) {
        #get_in_touch_form form .form-group .description-area {
          height: 130px; } }
    #get_in_touch_form form .form-group .address-wrapper {
      padding: 0;
      width: 100%; }
      #get_in_touch_form form .form-group .address-wrapper:first-child {
        margin-bottom: 10px; }
      @media screen and (min-width: 992px) {
        #get_in_touch_form form .form-group .address-wrapper {
          width: 172px; }
          #get_in_touch_form form .form-group .address-wrapper:first-child {
            margin-bottom: 0; } }
    #get_in_touch_form form .form-group .email-phone-group {
      flex-direction: column; }
      @media screen and (min-width: 992px) {
        #get_in_touch_form form .form-group .email-phone-group {
          flex-direction: row;
          justify-content: space-between; } }
  #get_in_touch_form form .checkbox {
    margin-bottom: 0; }
    #get_in_touch_form form .checkbox > .checkbox-label {
      font-size: 0.78571em; }
  @media screen and (max-width: 767px) {
    #get_in_touch_form form {
      padding: 0 47px; }
      #get_in_touch_form form .checkbox {
        margin-top: 15px;
        margin-bottom: 10px; } }
  @media screen and (min-width: 992px) {
    #get_in_touch_form form {
      padding: 0 15px; } }
  @media screen and (min-width: 1200px) {
    #get_in_touch_form form {
      padding: 0 49px; } }
