/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
#hotels_page .common-container .breadcrumb-path {
  padding-left: 0; }
  #hotels_page .common-container .breadcrumb-path .breadcrumb li:before {
    color: #3462ae; }
  @media screen and (min-width: 768px) {
    #hotels_page .common-container .breadcrumb-path .breadcrumb li {
      font-size: 0.85714em; } }
  @media screen and (min-width: 992px) {
    #hotels_page .common-container .breadcrumb-path .breadcrumb li {
      font-size: 1.14286em; } }

#hotels_page .sort-wrapper {
  padding-left: 6px;
  padding-right: 6px; }
  #hotels_page .sort-wrapper h4 {
    text-transform: uppercase;
    margin-right: 7px;
    font-size: 1em;
    letter-spacing: 2.7px;
    color: #3462ae; }
  @media screen and (min-width: 768px) {
    #hotels_page .sort-wrapper {
      padding-left: 10px; }
      #hotels_page .sort-wrapper h4 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.85714em; } }
  @media screen and (min-width: 992px) {
    #hotels_page .sort-wrapper {
      padding-left: 6px; }
      #hotels_page .sort-wrapper h4 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.14286em; } }
  #hotels_page .sort-wrapper .pricesort-wrapper a.dropdown-toggle {
    font-size: 0.78571em;
    padding: 3px 10px;
    height: 35px;
    width: 16.6em;
    text-align: left;
    border: solid 1px #d9d7d6;
    background: #fff;
    color: #3462ae; }
    #hotels_page .sort-wrapper .pricesort-wrapper a.dropdown-toggle .caret {
      position: relative;
      top: 4px;
      border-top-color: #14a651;
      border-width: 5px; }
  @media screen and (min-width: 768px) {
    #hotels_page .sort-wrapper .pricesort-wrapper a.dropdown-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 8px;
      font-size: 1em;
      height: 35px;
      width: 191px; }
      #hotels_page .sort-wrapper .pricesort-wrapper a.dropdown-toggle .caret {
        top: 0;
        border-width: 8px; } }
  @media screen and (min-width: 992px) {
    #hotels_page .sort-wrapper .pricesort-wrapper a.dropdown-toggle {
      font-size: 1.14286em; } }
  #hotels_page .sort-wrapper .pricesort-wrapper .dropdown-menu {
    width: 100%; }
    #hotels_page .sort-wrapper .pricesort-wrapper .dropdown-menu li > a {
      font-size: 1.14286em;
      color: #3462ae; }
    #hotels_page .sort-wrapper .pricesort-wrapper .dropdown-menu li:hover {
      background-color: #14a651; }

@media screen and (min-width: 992px) {
  #hotels_page .sort-wrapper {
    justify-content: flex-end;
    padding-right: 0; } }
