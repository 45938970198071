/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.search-page-banner__wrap {
  min-height: 322px;
  background-image: linear-gradient(to bottom, rgba(46, 41, 39, 0.67), rgba(98, 89, 86, 0.29));
  position: relative;
  padding-top: 114px;
  padding-bottom: 52px; }

.search-page-banner__image, .search-page-banner__layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%; }

.search-page-banner__image {
  object-fit: cover; }

.search-page-banner__layer {
  z-index: 2;
  background: rgba(97, 89, 86, 0.3); }

.search-page-banner__caption {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3; }

.search-page-banner__title {
  font-family: "Libre Baskerville", serif;
  font-size: 2.78571em;
  color: #fff; }

.search-page-banner__description {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.42857em;
  margin-bottom: 4px;
  color: #fff;
  line-height: 1.4em; }
  .search-page-banner__description--niseko {
    font-size: 1.71429em; }

.search-page-banner__outstanding-services {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.14286em;
  color: #fff;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  width: 68%; }

.promotion-search-page-banner__wrap {
  min-height: auto; }

@media screen and (max-width: 767px) {
  .search-page-banner {
    z-index: 100;
    width: 100%; }
    .search-page-banner__wrap {
      min-height: 182px;
      padding-top: 0;
      padding-bottom: 0; } }

@media screen and (min-width: 768px) {
  .search-page-banner__caption {
    width: 685px;
    margin: 0 auto; } }

@media screen and (min-width: 992px) {
  .search-page-banner__caption {
    width: 740px; } }
