/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.enquire-form .modal-body {
  padding-left: 33px;
  padding-right: 33px;
  padding-top: 0; }
  @media screen and (max-width: 767px) {
    .enquire-form .modal-body {
      padding: 0 18px 20px; }
      .enquire-form .modal-body .enquire-testimonial {
        padding: 0 12px 0 18px; } }

.enquire-form__info {
  color: #3462ae;
  font-family: "Nunito Sans", sans-serif; }
  .enquire-form__info p:first-child {
    font-size: 0.92857em;
    letter-spacing: 2px;
    margin-bottom: 0; }
  @media screen and (min-width: 768px) {
    .enquire-form__info p:first-child {
      font-size: 1.14286em;
      letter-spacing: 2.7px; } }
  .enquire-form__info p:nth-child(2) {
    font-weight: 600;
    font-size: 1.42857em;
    color: #14a651; }
    .enquire-form__info p:nth-child(2) .price {
      font-size: 1.4em;
      margin-left: 6px; }

.enquire-form__checkin-info {
  background-color: #d9d7d6;
  padding: 16px 33px;
  margin-left: -33px;
  margin-right: -33px; }
  @media screen and (min-width: 768px) {
    .enquire-form__checkin-info .hotel-datepicker-width-2-inputs .datepicker {
      left: -33px; } }

.enquire-form__checkin-info,
.enquire-form .textbox-group {
  margin-bottom: 12px; }

.enquire-form__additional {
  margin-bottom: 10px; }
  .enquire-form__additional a {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.14286em;
    color: #14a651 !important;
    cursor: pointer; }

@media screen and (min-width: 768px) {
  .enquire-form__additional a {
    font-size: 1.14286em; } }

.enquire-form__team-info {
  margin-top: 25px;
  padding-left: 20px;
  padding-right: 20px; }
  .enquire-form__team-info p {
    font-family: "Nunito Sans", sans-serif;
    text-align: center;
    width: calc(100% - 138px); }
    .enquire-form__team-info p i {
      display: block;
      color: #3462ae; }
      .enquire-form__team-info p i:first-child {
        font-size: 1.07143em;
        margin-bottom: 20px; }
        @media screen and (max-width: 767px) {
          .enquire-form__team-info p i:first-child {
            margin-bottom: 14px; } }
      .enquire-form__team-info p i:nth-child(2) {
        font-size: 0.85714em; }
        .enquire-form__team-info p i:nth-child(2) .price {
          font-weight: 600; }
  .enquire-form__team-info img {
    width: 138px;
    height: 138px; }

@media (min-width: 768px) {
  .enquire-form .col-xs-12,
  .enquire-form .col-xs-6,
  .enquire-form .col-md-12 {
    padding: 0; } }

.enquire-form input.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 36px;
  font-weight: 300;
  font-size: 1.07143em;
  padding: 12px 18px; }
  .enquire-form input.form-control::placeholder {
    color: #000; }

.enquire-form .input-group,
.enquire-form button[type="submit"] {
  width: 100%; }

.enquire-form .btn-primary {
  font-size: 1.14286em !important;
  letter-spacing: 1.9px; }

.enquire-form .form-dropdown {
  margin-top: 12px; }

.enquire-form .dropdown-adult {
  padding-right: 10px; }

.enquire-form .dropdown-children {
  padding-left: 10px; }

.enquire-form .dropdown-adult .dropdown-toggle,
.enquire-form .dropdown-children .dropdown-toggle {
  padding-left: 18px;
  padding-right: 18px;
  font-size: 1.07143em; }
  @media screen and (max-width: 374px) {
    .enquire-form .dropdown-adult .dropdown-toggle,
    .enquire-form .dropdown-children .dropdown-toggle {
      padding: 8px 12px; } }

.enquire-form .modal-dialog .modal-body input.form-control:not(.textbox), .enquire-form .modal-dialog .modal-body input.form-control:not(.textbox)::placeholder {
  color: #3462ae;
  font-weight: 400;
  font-size: 1.07143em; }

.enquire-form .modal-dialog .modal-body .input-group-datepicker {
  height: 35px; }

@media screen and (min-width: 768px) {
  .enquire-form .modal-dialog .modal-body .hotel-datepicker-width-2-inputs .short-datepicker:before {
    top: 10px;
    right: 22px; }
  .enquire-form .modal-dialog .modal-body input.form-control:not(.textbox) {
    color: #3462ae; } }
