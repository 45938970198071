/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.single-date-wrapper .SingleDatePickerInput {
  display: flex;
  flex-direction: row-reverse;
  border: none;
  border-radius: 0; }
  .single-date-wrapper .SingleDatePickerInput > button {
    margin-left: 0;
    margin-right: 10px;
    padding: 5px 10px 10px 0; }
  .single-date-wrapper .SingleDatePickerInput .DateInput {
    width: 276px; }
    .single-date-wrapper .SingleDatePickerInput .DateInput .DateInput_input {
      font-family: "Nunito Sans", sans-serif;
      font-size: 1em;
      border: none;
      padding: 6px 16px;
      cursor: pointer;
      font-weight: 400; }
      .single-date-wrapper .SingleDatePickerInput .DateInput .DateInput_input, .single-date-wrapper .SingleDatePickerInput .DateInput .DateInput_input::placeholder {
        color: #3462ae;
        font-weight: 400;
        opacity: 1; }
      @media screen and (max-width: 767px) {
        .single-date-wrapper .SingleDatePickerInput .DateInput .DateInput_input {
          padding-left: 18px;
          padding-right: 18px; } }
    .single-date-wrapper .SingleDatePickerInput .DateInput > .DateInput_fang {
      display: none; }
  .single-date-wrapper .SingleDatePickerInput .SingleDatePicker_picker {
    z-index: 3; }
