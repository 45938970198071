/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
#book-thank-you__top-banner img {
  width: 100%;
  height: 88px;
  object-fit: cover; }

#book-thank-you h2,
#book-thank-you p,
#book-thank-you a {
  text-align: center; }

#book-thank-you .back-search-link {
  display: block;
  margin-bottom: 24px;
  font-size: 1.07143em;
  text-decoration: underline; }
  @media screen and (min-width: 992px) {
    #book-thank-you .back-search-link {
      margin-bottom: 34px; } }
  @media screen and (min-width: 1200px) {
    #book-thank-you .back-search-link {
      margin-bottom: 46px; } }

#book-thank-you h2 {
  font-family: "Libre Baskerville", serif;
  font-size: 2.78571em;
  color: #14a651;
  margin-top: 40px; }
  @media screen and (min-width: 992px) {
    #book-thank-you h2 {
      margin-top: 56px; } }

#book-thank-you__coconut img {
  width: 213px;
  height: 213px;
  margin: 10px auto 25px;
  display: block; }

#book-thank-you p {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.42857em;
  color: #3462ae;
  line-height: 1.4;
  margin-bottom: 0; }

#book-thank-you #follow-us {
  margin-bottom: 16px; }
  #book-thank-you #follow-us h3 {
    display: none; }

#book-thank-you .follow-us__desc {
  margin-bottom: 25px; }

#book-thank-you .follow-us__wrapper {
  width: 100%; }

@media screen and (max-width: 767px) {
  #book-thank-you h2 {
    font-size: 2.35714em; }
  #book-thank-you p {
    font-size: 1.42857em; }
  #book-thank-you__coconut img {
    margin-bottom: 0; }
  #book-thank-you .common-container .row {
    display: flex;
    justify-content: center; }
    #book-thank-you .common-container .row .thank-wrapper {
      width: 340px; } }
