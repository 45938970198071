/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.enquire__thank-you .modal-header {
  border: none; }

.enquire__thank-you h4 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 2em;
  font-weight: 600;
  margin: 0;
  color: #14a651; }

.enquire__thank-you .modal-body {
  padding-top: 7px; }
  .enquire__thank-you .modal-body p,
  .enquire__thank-you .modal-body a {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.07143em;
    color: #3462ae; }
  .enquire__thank-you .modal-body p:first-of-type:not(.full-row) {
    width: 70%;
    margin: 5px auto 0  auto; }
  .enquire__thank-you .modal-body .phone-number {
    margin-top: 6px;
    margin-bottom: 0; }
  .enquire__thank-you .modal-body img {
    margin: 10px auto 0 auto;
    width: 210px;
    height: 210px;
    display: block; }
  .enquire__thank-you .modal-body a {
    color: #14a651;
    margin-top: 3px;
    text-decoration: underline !important;
    display: block;
    width: 100%; }

@media screen and (min-width: 768px) {
  .enquire__thank-you h4 {
    margin: 7px 0; }
  .enquire__thank-you .modal-body {
    padding-top: 20px; }
    .enquire__thank-you .modal-body p,
    .enquire__thank-you .modal-body a {
      font-size: 1em;
      line-height: 1.43; }
    .enquire__thank-you .modal-body p {
      margin-top: 15px;
      margin-bottom: 2px; }
      .enquire__thank-you .modal-body p:first-of-type:not(.full-row) {
        width: 45%; }
  .enquire__thank-you .modal-dialog .modal-body {
    padding-bottom: 34px; }
    .enquire__thank-you .modal-dialog .modal-body a {
      padding-bottom: 25px; }
    .enquire__thank-you .modal-dialog .modal-body img {
      width: 238px;
      height: 238px;
      margin-top: 5px; }
    .enquire__thank-you .modal-dialog .modal-body .phone-number {
      margin-top: 10px; } }
