/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.payment-form-footer .form-group {
  margin-bottom: 0; }

.payment-form-footer .dropdown-menu.policy .title {
  padding: 15px;
  background-color: #3462ae; }
  .payment-form-footer .dropdown-menu.policy .title h4 {
    margin: 0;
    color: #fff; }

.payment-form-footer .dropdown-menu.policy .content {
  padding: 15px; }
