/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
#__lpform_name_icon {
  display: none !important; }

.we-price-match__info p:first-child {
  font-size: 1.42857em;
  font-weight: 600;
  padding: 0 20px;
  margin-bottom: 8px;
  color: #14a651; }

.we-price-match .modal-dialog .modal-body .datepicker-wrapper input.form-control:not(.textbox) {
  font-size: 1em;
  height: 2.42857em; }
  .we-price-match .modal-dialog .modal-body .datepicker-wrapper input.form-control:not(.textbox), .we-price-match .modal-dialog .modal-body .datepicker-wrapper input.form-control:not(.textbox)::placeholder {
    color: #3462ae; }

.we-price-match .modal-dialog .modal-body #we-price-match__form_submit .btn-primary {
  padding: 7px 12px;
  font-weight: 600; }

.we-price-match .textbox::placeholder {
  color: #000;
  opacity: 1; }

.we-price-match .textbox::-ms-input-placeholder {
  color: #000; }

.we-price-match .form-group {
  margin-bottom: 12px; }
  .we-price-match .form-group .comment-text {
    font-size: 1em;
    height: 5.5em;
    font-weight: 300;
    padding-left: 18px;
    padding-right: 18px;
    resize: none; }
  .we-price-match .form-group .comment-bold {
    font-weight: bold; }
