/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
#newsletter_form .modal-header {
  padding: 0;
  border: 0;
  position: relative; }
  #newsletter_form .modal-header .close {
    position: absolute;
    right: 40px;
    top: 42px;
    z-index: 3; }
    #newsletter_form .modal-header .close span {
      width: 21px;
      height: 22px;
      display: block;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIxIDIyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMTRhNjUxIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjA1NiAyLjA4MkwxOC45NDQgMjAuNU0xOC45NDQgMi4wODJMMi4wNTYgMjAuNSIvPgogICAgPC9nPgo8L3N2Zz4K) center center no-repeat; }

#newsletter_form .modal-body {
  padding: 0; }
  #newsletter_form .modal-body .newsletter-wrapper {
    padding: 35px 50px;
    color: #615956; }
    #newsletter_form .modal-body .newsletter-wrapper h3 {
      font-family: "Libre Baskerville", serif;
      font-size: 1.78571em;
      line-height: normal;
      margin-top: 0;
      color: #615956; }
      #newsletter_form .modal-body .newsletter-wrapper h3 span {
        font-size: 1.48em;
        display: block; }
    #newsletter_form .modal-body .newsletter-wrapper h4 {
      font-family: "Nunito Sans", sans-serif;
      font-size: 1em;
      letter-spacing: 2.3px;
      color: #3462ae; }
    #newsletter_form .modal-body .newsletter-wrapper .newsletter-submit {
      width: 100%; }
    #newsletter_form .modal-body .newsletter-wrapper .input-wrapper input.form-control:not(.textbox) {
      height: 30px;
      font-size: 1.07143em;
      color: #473935;
      padding-left: 15px;
      padding-right: 15px; }

#newsletter_form .newsletter-img {
  padding-left: 0;
  padding-right: 0; }

@media (min-width: 768px) {
  #newsletter_form .modal-header .close {
    right: 16px;
    top: 16px; }
    #newsletter_form .modal-header .close span {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIxIDIyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRkZGIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjA1NiAyLjA4MkwxOC45NDQgMjAuNU0xOC45NDQgMi4wODJMMi4wNTYgMjAuNSIvPgogICAgPC9nPgo8L3N2Zz4K) center center no-repeat; }
  #newsletter_form .newsletter-wrapper {
    width: calc(100% - 327px); }
    #newsletter_form .newsletter-wrapper h3 {
      margin-bottom: 0; }
    #newsletter_form .newsletter-wrapper h4 {
      font-size: 1em;
      letter-spacing: 2.3px;
      margin-top: 7px;
      margin-bottom: 0; }
    #newsletter_form .newsletter-wrapper .input-wrapper {
      margin-top: 16px; }
      #newsletter_form .newsletter-wrapper .input-wrapper .form-group {
        margin-bottom: 10px; }
    #newsletter_form .newsletter-wrapper .newsletter-submit {
      font-weight: 600;
      letter-spacing: 1.6px;
      padding: 6px 12px; } }
