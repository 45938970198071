/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.hotel-popover {
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 1000;
  border: solid 1px #d9d7d6; }
  .hotel-popover .popover-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d9d7d6;
    margin: 0;
    padding: 12px 18px;
    background-color: #fff; }
    .hotel-popover .popover-header__title {
      margin: 0;
      padding: 0;
      font-size: 1.14286em;
      letter-spacing: 2.7px; }
    .hotel-popover .popover-header__close {
      background: transparent;
      border: none; }
      .hotel-popover .popover-header__close__image-icon {
        display: block;
        width: 20px;
        height: 20px;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIxIDIyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMTRhNjUxIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjA1NiAyLjA4MkwxOC45NDQgMjAuNU0xOC45NDQgMi4wODJMMi4wNTYgMjAuNSIvPgogICAgPC9nPgo8L3N2Zz4K) center center no-repeat; }
  @media screen and (min-width: 768px) {
    .hotel-popover {
      width: 390px; } }
  @media screen and (min-width: 992px) {
    .hotel-popover {
      width: 574px; } }
  @media screen and (min-width: 1200px) {
    .hotel-popover {
      width: 718px; } }
