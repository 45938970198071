/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.truncate-description {
  max-height: 84px;
  transition: max-height 0.5s;
  overflow: hidden; }

.read-more, .read-more:link, .read-more:visited, .read-more:hover, .read-more:active {
  position: relative;
  font-weight: bold;
  text-decoration: none;
  margin-left: 5px;
  color: #fff;
  cursor: pointer; }

.read-more:after {
  content: '';
  position: absolute;
  display: block;
  bottom: 3px;
  right: 0;
  height: 1px;
  width: 100%;
  background: #fff; }
