/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
#homepage-search {
  position: absolute;
  top: 263px;
  z-index: 100;
  width: 100%; }
  @media screen and (max-width: 767px) {
    #homepage-search {
      position: static;
      height: 516px;
      background: url(https://storage.googleapis.com/luxuryvillasandhomes.appspot.com/lvh_static/webpack_bundles/ac9ed700e73116503d150887f544f89c.jpg) no-repeat bottom left transparent;
      background-size: 100% auto; }
      #homepage-search a.btn-search {
        font-size: 1.14286em; }
      #homepage-search .mobile-heading h1 {
        font-family: "Libre Baskerville", serif;
        color: #fff;
        font-size: 2.35714em;
        line-height: 1.3;
        margin-bottom: 22px; } }
  @media screen and (min-width: 320px) and (max-width: 375px) {
    #homepage-search {
      background-size: cover; } }
  #homepage-search .search-container a.dropdown-toggle,
  #homepage-search .search-container .search-input,
  #homepage-search .search-container .checkin-span {
    font-weight: 400; }
  #homepage-search .search-container a.dropdown-toggle,
  #homepage-search .search-container a.btn-search {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  @media screen and (max-width: 767px) {
    #homepage-search .search-container a.dropdown-toggle,
    #homepage-search .search-container .search-input,
    #homepage-search .search-container .checkin-span {
      font-size: 1.14286em; }
    #homepage-search .search-container a.btn-search {
      justify-content: center; }
    #homepage-search .search-container .checkin-span {
      padding-left: 6px; }
    #homepage-search .search-container a.destination-toggle {
      font-weight: 700; } }
  @media screen and (max-width: 374px) {
    #homepage-search .search-container a.dropdown-toggle,
    #homepage-search .search-container .search-input,
    #homepage-search .search-container .checkin-span {
      font-size: 1em; }
    #homepage-search .search-container .checkin-span {
      padding-left: 2px; } }
  @media screen and (min-width: 768px) {
    #homepage-search .search-container a.dropdown-toggle,
    #homepage-search .search-container .search-input,
    #homepage-search .search-container .checkin-span,
    #homepage-search .search-container a.btn-search {
      height: 34px; } }
  @media screen and (min-width: 992px) {
    #homepage-search .search-container a.dropdown-toggle,
    #homepage-search .search-container .search-input,
    #homepage-search .search-container .checkin-span,
    #homepage-search .search-container a.btn-search {
      height: 30px;
      font-size: 1.07143em; } }
  @media screen and (max-width: 1199px) and (min-width: 992px) and (orientation: landscape) {
    #homepage-search .search-container a.dropdown-toggle,
    #homepage-search .search-container .search-input,
    #homepage-search .search-container .checkin-span,
    #homepage-search .search-container a.btn-search {
      height: 34px; } }

.quick-search-component {
  padding-top: 86px;
  padding-bottom: 50px; }
  .quick-search-component .search-container {
    display: flex;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%; }
    @media screen and (max-width: 767px) {
      .quick-search-component .search-container {
        flex-direction: column;
        align-items: center; }
        .quick-search-component .search-container .destination-menu.open.select > .dropdown-menu {
          width: 270px; } }
    .quick-search-component .search-container .search-item {
      padding-left: 10px;
      padding-right: 10px; }
      @media screen and (max-width: 767px) {
        .quick-search-component .search-container .search-item {
          width: 88%;
          margin-bottom: 25px; } }
    .quick-search-component .search-container .select.bedrooms ul.dropdown-menu {
      min-width: 145px;
      overflow: hidden; }
      .quick-search-component .search-container .select.bedrooms ul.dropdown-menu li a {
        padding: 5px 14px;
        font-size: 1.07143em; }
      @media screen and (max-width: 767px) {
        .quick-search-component .search-container .select.bedrooms ul.dropdown-menu li a {
          padding: 10px 20px; } }
    .quick-search-component .search-container .hotel-datepicker-width-2-inputs {
      max-width: 236px; }
      @media screen and (max-width: 767px) {
        .quick-search-component .search-container .hotel-datepicker-width-2-inputs {
          max-width: 100%; } }
      @media screen and (max-width: 767px) {
        .quick-search-component .search-container .hotel-datepicker-width-2-inputs .datepicker {
          left: 50%;
          transform: translateX(-50%);
          width: 320px; } }
      @media screen and (max-width: 374px) {
        .quick-search-component .search-container .hotel-datepicker-width-2-inputs .datepicker {
          width: 316px; } }
      .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker input {
        width: 164px;
        padding-left: 15px;
        padding-right: 15px;
        background: #fff; }
        @media screen and (max-width: 1199px) {
          .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker input {
            width: 130px; } }
      .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker .search-input {
        box-shadow: none;
        padding: 6px 10px; }
        @media screen and (max-width: 767px) {
          .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker .search-input {
            height: 44px; } }
      .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker .date-to-go {
        font-size: 1em;
        width: 100% !important;
        padding-right: 0px; }
        @media screen and (max-width: 767px) {
          .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker .date-to-go {
            padding-left: 14px; } }
        @media screen and (max-width: 374px) {
          .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker .date-to-go {
            padding-left: 10px; } }
      .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker .date-to-go__has-value {
        width: 100% !important; }
      .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker .date-to-live {
        width: 156px;
        font-size: 1em;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAOCAYAAAAbvf3sAAAAAXNSR0IArs4c6QAAAWNJREFUKBWVkj1LA0EQhmf2TowJgj8hf8DSNAk2VrYKOU1ATk/T2SioZWwECz/A0q+ARBJBsLOJoKJNmhQpLPwTwpEzkNsdZ+4kWCSoAzvMPPO+u8feInzHSmHBBTKXqCB3Xr15FewV81ky8AKoli+uaxVhWCqVRnTnI4MGHQO0zoZtRNWUIZHJsGFfAZ6QorqVmmiiV3CeiGhaBL8FIj6rv4plM9HaUrDzkADbUg8LBJpkw0ZkUAiN02rtfphY+FrRmdUEsUHAasGZMwQHtgXz2kBZmKWgHGq45Q03uf0UpiT9J6JPEsMY2o0uGReS422709kRRsnUu+X7bgJVqwthVlj/hIDCJQ36UQf+TI/CO1lSC5OZiCX6J6AFD6ShjAloUYB7MoxrZjwDA2lhfcPZVf2N+12BHJUoxylifEtpaW3+Bz2+35y3mB+N54Mza6ZEywY45veyRT9OG2ghCPmdHX0BVMuRACfqx/4AAAAASUVORK5CYII=);
        background-position: 90% center;
        background-size: auto;
        background-repeat: no-repeat;
        padding-left: 5px; }
        @media screen and (max-width: 767px) {
          .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker .date-to-live {
            background: #fff;
            padding-right: 0; } }
      .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker .date-to-live__has-value {
        width: 128px; }
      @media screen and (max-width: 767px) {
        .quick-search-component .search-container .hotel-datepicker-width-2-inputs .input-group-datepicker {
          height: 44px; } }
      .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-in, .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-out {
        margin-bottom: 0; }
      .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-in {
        position: relative; }
        .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-in:before {
          position: absolute;
          top: 1px;
          right: 3px;
          content: '-';
          display: block;
          width: 3px;
          height: 1px; }
          @media screen and (max-width: 767px) {
            .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-in:before {
              top: 12px;
              right: 4px;
              color: #000; } }
          @media screen and (min-width: 768px) {
            .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-in:before {
              top: 7px;
              right: 3px; } }
          @media screen and (min-width: 992px) {
            .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-in:before {
              top: 5px; } }
          @media screen and (max-width: 1199px) and (min-width: 992px) and (orientation: landscape) {
            .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-in:before {
              top: 7px; } }
      .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-out {
        width: 100%;
        float: right; }
        .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-out:after {
          clear: both; }
        .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-out .input-group-datepicker {
          position: relative; }
          .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-out .input-group-datepicker:before {
            position: absolute;
            display: block;
            content: '';
            top: 5px;
            right: 13px;
            width: 9px;
            height: 11px;
            z-index: 2; }
            @media screen and (min-width: 768px) {
              .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-out .input-group-datepicker:before {
                top: 8px;
                right: 15px;
                width: 13px;
                height: 16px; } }
            @media screen and (min-width: 992px) {
              .quick-search-component .search-container .hotel-datepicker-width-2-inputs .check-out .input-group-datepicker:before {
                right: 19px; } }

.search-container .dropdown-menu {
  max-height: 610px;
  overflow: auto;
  width: 100%; }

.search-container .btn,
.search-container .form-control {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 34px;
  text-align: left;
  cursor: pointer; }

.search-container .form-control {
  width: 100%; }

.search-container .btn-group,
.search-container .dropdown-toggle {
  width: 100%; }

.search-container a.dropdown-toggle {
  font-size: 1.35714em; }
  .search-container a.dropdown-toggle .caret {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 10px solid #14a651;
    float: right;
    position: relative;
    top: 9px; }
    @media screen and (max-width: 767px) {
      .search-container a.dropdown-toggle .caret {
        top: 0; } }

.search-container .btn-primary {
  border-radius: 0;
  font-weight: bold; }

.search-container .btn-search {
  text-transform: uppercase;
  height: 30px;
  padding: 6px 14px;
  letter-spacing: 1.6px; }
  .search-container .btn-search:hover, .search-container .btn-search:active, .search-container .btn-search:focus {
    border-color: #204d74; }
  @media screen and (max-width: 767px) {
    .search-container .btn-search {
      height: 38px; } }

.search-container .date-range-picker input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #000; }

.search-container .date-range-picker input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000;
  opacity: 1; }

.search-container .date-range-picker input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000;
  opacity: 1; }

.search-container .date-range-picker input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000; }

.search-container .date-range-picker input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000; }

.search-container .date-range-picker input:hover, .search-container .date-range-picker input:active, .search-container .date-range-picker input:focus {
  background-color: transparent; }

.search-container .date-range-picker input {
  font-size: 1.1em; }

.search-container .search-input {
  font-size: 1.35714em;
  height: 44px;
  padding: 9px 20px 9px 28px; }
  @media screen and (max-width: 767px) {
    .search-container .search-input {
      padding: 9px 14px; }
      .search-container .search-input span {
        font-weight: 400; } }
  @media screen and (max-width: 374px) {
    .search-container .search-input {
      padding: 10px; } }

@media screen and (max-width: 767px) {
  .search-container .destination.open .mega-menu.destinations ol.flyout-column {
    padding-left: 1em; }
    .search-container .destination.open .mega-menu.destinations ol.flyout-column li {
      padding: 3px 0; }
      .search-container .destination.open .mega-menu.destinations ol.flyout-column li a {
        font-size: 1.07143em;
        display: block; }
      .search-container .destination.open .mega-menu.destinations ol.flyout-column li.flyout-group {
        margin-top: 2em; }
        .search-container .destination.open .mega-menu.destinations ol.flyout-column li.flyout-group a {
          font-weight: bold; }
  .search-container .btn {
    width: 100%; } }

@media screen and (min-width: 768px) {
  .quick-search-component {
    padding-top: 0;
    padding-bottom: 0; }
    .quick-search-component .search-container {
      width: 660px;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 0; }
      .quick-search-component .search-container .search-item {
        padding-left: 5px;
        padding-right: 5px; }
        .quick-search-component .search-container .search-item--select-where, .quick-search-component .search-container .search-item--select-bed {
          width: 155px; }
        .quick-search-component .search-container .search-item--select-time {
          width: 246px; }
        .quick-search-component .search-container .search-item--wrap-btn-search {
          width: calc(100% - 556px); }
      .quick-search-component .search-container .search-input {
        font-size: 1em;
        height: 30px;
        padding: 6px 10px;
        background: #fff; } }
      @media screen and (min-width: 768px) and (min-width: 992px) {
        .quick-search-component .search-container .search-input {
          padding: 6px 8px; } }

@media screen and (min-width: 768px) {
      .quick-search-component .search-container a.dropdown-toggle {
        font-size: 1em; }
        .quick-search-component .search-container a.dropdown-toggle .caret {
          top: 0;
          border-width: 7px; } }

@media screen and (min-width: 992px) {
  .quick-search-component .search-container {
    width: 700px; }
    .quick-search-component .search-container .search-item {
      padding-left: 10px;
      padding-right: 10px; }
      .quick-search-component .search-container .search-item--select-where, .quick-search-component .search-container .search-item--select-bed {
        width: 165px; }
      .quick-search-component .search-container .search-item--select-time {
        width: 256px; }
      .quick-search-component .search-container .search-item--wrap-btn-search {
        width: calc(100% - 586px); } }

@media screen and (min-width: 1200px) {
  .search-container .destination.open .dropdown-menu.flyout-wrapper {
    width: 830px;
    left: 101%;
    top: -222px; } }

@media screen and (max-width: 767px) {
  .search-container .destination-menu.open .dropdown-menu .menu-option {
    margin: 0 0 6px; }
    .search-container .destination-menu.open .dropdown-menu .menu-option > a {
      padding-left: 20px;
      line-height: 1.7; }
  .search-container .destination-menu.open .dropdown-menu .destination-toggle, .search-container .destination-menu.open .dropdown-menu .menu-option > a {
    font-size: 1.14286em;
    font-weight: 700;
    color: #14a651;
    text-decoration: none; }
  .search-container .destination-menu.open .dropdown-menu .menu-option > a {
    color: #000; }
  .search-container .destination-menu.open .dropdown-menu .destination-toggle {
    display: block;
    margin-top: 5px;
    padding: 5px 0; }
  .search-container .destination-menu.open .dropdown-menu .concierge {
    margin-top: 0;
    padding-top: 0; } }
