/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
#PromotionsPage .search-page__list-hotels-fit {
  padding: 0 15px; }

@media screen and (max-width: 767px) {
  #PromotionsPage .search-page__wrap {
    margin-top: 182px; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  #PromotionsPage .search-page__list-hotels-fit {
    width: 438px;
    margin: 0 auto; } }

#PromotionsPage .wrap-main-content {
  min-height: calc(100vh - 365px); }
  @media screen and (min-width: 768px) {
    #PromotionsPage .wrap-main-content {
      min-height: calc(100vh - 404px); } }
  @media screen and (min-width: 992px) {
    #PromotionsPage .wrap-main-content {
      min-height: calc(100vh - 374px); } }

#PromotionsPage .text-blue {
  color: #3462ae; }

#PromotionsPage .text-second-font {
  font-family: "Libre Baskerville", serif; }
