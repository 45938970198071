/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
.we-price-match-thank-you .modal-dialog .modal-body {
  padding-top: 26px;
  padding-bottom: 20px; }
  .we-price-match-thank-you .modal-dialog .modal-body h4 {
    margin-top: 0;
    margin-bottom: 8px; }
  .we-price-match-thank-you .modal-dialog .modal-body p:first-of-type:not(.full-row) {
    width: 77%;
    text-align: center;
    margin-bottom: 5px;
    padding: 0; }
  .we-price-match-thank-you .modal-dialog .modal-body img {
    width: 16.35714em;
    height: 16.35714em; }
  .we-price-match-thank-you .modal-dialog .modal-body .phone-number {
    margin-top: 20px; }
  .we-price-match-thank-you .modal-dialog .modal-body .back-search {
    padding: 0;
    margin-top: 0;
    margin-bottom: 44px; }
