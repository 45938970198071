/**
OVERRIDE BOOTSTRAP VARIABLES
 */
/**
Left menu on search page
 */
/**
Side bar on payment page
 */
/**
Owner image width on about villa management page
 */
/**
Correspond image width on about villa management page
 */
/**
Footer social icons
*/
#__lpform_id_first_name_icon {
  opacity: 0 !important; }

.payment {
  /* Update layout width to map with design */
  /* Top banner */
  /* Overlay on top banner */
  /* Coupon form */ }
  @media screen and (max-width: 767px) {
    .payment .form-content {
      margin-left: 0;
      margin-right: 0; } }
  @media screen and (min-width: 768px) {
    .payment .card-form {
      padding: 0 30px 35px; } }
  @media screen and (min-width: 992px) {
    .payment .card-form {
      padding: 0 15px 56px; } }
  .payment__info__block {
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d7d6; }
    .payment__info__block h3 {
      margin-top: 24px;
      margin-bottom: 10px; }
    @media screen and (max-width: 767px) {
      .payment__info__block {
        padding-bottom: 20px; }
        .payment__info__block:last-child {
          padding-bottom: 30px; } }
    @media screen and (min-width: 992px) {
      .payment__info__block h3 {
        margin-top: 20px;
        margin-bottom: 15px; } }
  .payment__info input[name="first_name"] {
    background-image: none !important; }
  .payment__info #cancellation-policy,
  .payment__info .term-service-policy a {
    text-decoration: underline; }
  .payment__info #cancellation-policy {
    text-shadow: none;
    margin-left: 0;
    vertical-align: baseline;
    font-size: 1em;
    margin-left: 5px; }
    @media screen and (min-width: 992px) {
      .payment__info #cancellation-policy {
        margin-left: 0; } }
    @media screen and (min-width: 1200px) {
      .payment__info #cancellation-policy {
        margin-left: 3px; } }
  .payment__info .term-service-policy {
    display: inline;
    margin-left: 0; }
    .payment__info .term-service-policy p {
      display: inline; }
  .payment__info button[type="submit"] {
    width: 100%;
    font-size: 1.14286em;
    margin-top: 14px;
    font-weight: 600; }
  .payment__info .book-form__vote {
    margin-top: 10px;
    display: block;
    line-height: 1.07;
    font-weight: 600; }
    .payment__info .book-form__vote, .payment__info .book-form__vote > a {
      color: #3462ae; }
    .payment__info .book-form__vote > a {
      font-weight: 700;
      margin-left: 4px; }
      .payment__info .book-form__vote > a > img {
        width: 18px;
        margin-bottom: 6px; }
  .payment__info .form-dropdown a {
    height: 36px;
    background-image: none;
    background-color: #fff;
    border: 1px solid #d9d7d6;
    padding-left: 18px; }
    .payment__info .form-dropdown a > span {
      color: #000;
      font-weight: 300; }
    @media screen and (max-width: 767px) {
      .payment__info .form-dropdown a {
        padding-left: 18px; } }
    @media screen and (min-width: 992px) {
      .payment__info .form-dropdown a {
        padding-left: 27px; } }
  .payment__info .booking-wrapper .btn-primary {
    padding: 6px 16px;
    font-weight: 600;
    letter-spacing: 1.9px; }
  @media screen and (max-width: 767px) {
    .payment__info .booking-wrapper {
      border-top: solid 1px #d9d7d6; } }
  @media screen and (max-width: 767px) {
    .payment .book-button-row {
      margin: 30px -15px 0;
      padding: 0;
      background: #d9d7d6; }
      .payment .book-button-row .booking-wrapper {
        margin-bottom: 0;
        padding: 4px 33px;
        border: none; } }
  .payment__section-title {
    color: #3462ae;
    font-size: 1.14286em;
    font-family: "Nunito Sans", sans-serif;
    letter-spacing: 2.7px;
    font-weight: normal; }
    @media screen and (max-width: 767px) {
      .payment__section-title {
        font-size: 1.14286em;
        padding: 0; } }
  @media screen and (min-width: 768px) {
    .payment__info {
      padding-right: 40px; }
    .payment .sidebar {
      padding-left: 2px; } }
  @media screen and (min-width: 992px) {
    .payment__info,
    .payment .sidebar {
      padding: 0; }
    .payment__info {
      width: calc(100% - 456px - 56px); }
    .payment .sidebar {
      width: 456px; } }
  @media screen and (min-width: 1200px) {
    .payment__info {
      width: calc(100% - 456px - 256px); } }
  @media screen and (max-width: 767px) {
    .payment__info {
      padding: 0 18px; } }
  @media screen and (max-width: 767px) {
    .payment .payment-form-footer {
      padding: 0 18px; }
      .payment .payment-form-footer h3 {
        padding-left: 0;
        margin-bottom: 0; }
      .payment .payment-form-footer .checkbox-label {
        font-size: 1em; } }
  @media screen and (min-width: 768px) {
    .payment .payment-form-footer .checkbox-label {
      font-size: 1em; } }
  .payment .banner-wrapper {
    margin-left: 0;
    margin-right: 0; }
  .payment .top-image {
    padding-left: 0;
    padding-right: 0; }
    .payment .top-image .img-container img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
    @media (min-width: 768px) {
      .payment .top-image .img-container img {
        height: 275px; } }
    @media screen and (max-width: 767px) {
      .payment .top-image .img-container {
        min-height: 143px;
        overflow: hidden; }
        .payment .top-image .img-container img {
          float: right;
          height: 143px; } }
  .payment .overlay {
    bottom: 0;
    left: 0;
    padding: 7px 0;
    position: absolute;
    right: 0;
    top: 0; }
    .payment .overlay .common-container {
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 24px;
      height: 100%; }
    @media screen and (max-width: 767px) {
      .payment .overlay .common-container {
        padding-bottom: 8px; } }
    .payment .overlay h2,
    .payment .overlay h3 {
      color: #fff; }
    .payment .overlay h2 {
      font-family: "Libre Baskerville", serif;
      font-size: 2.35714em; }
    @media screen and (min-width: 768px) {
      .payment .overlay h2 {
        margin-bottom: 4px;
        font-size: 2.78571em; } }
    .payment .overlay h3 {
      margin: 10px 0 0;
      font-family: "Nunito Sans", sans-serif;
      font-size: 1.42857em; }
      @media screen and (max-width: 767px) {
        .payment .overlay h3 {
          display: inline; }
          .payment .overlay h3 + h3 {
            margin-left: 7px; } }
  .payment .coupon-form.form-group,
  .payment .coupon-form .form-group {
    margin-bottom: 0; }
  .payment .coupon-form .textbox {
    height: 35px; }
  .payment .coupon-form .payment-coupon-wrapper {
    padding: 0; }
  .payment a#cancellation-policy {
    color: #14a651; }
  .payment .currency {
    display: inline !important; }
  .payment .checkbox {
    padding-top: 0; }
    .payment .checkbox .control-label {
      padding-top: 3px; }
  .payment form .textbox {
    padding-left: 18px;
    font-size: 1em; }
    .payment form .textbox, .payment form .textbox::placeholder {
      font-weight: 300; }
    .payment form .textbox::placeholder {
      color: #000; }
    @media screen and (max-width: 767px) {
      .payment form .textbox {
        font-size: 1em;
        padding-left: 18px; } }
    @media screen and (min-width: 992px) {
      .payment form .textbox {
        padding-left: 27px; } }
  .payment form .control-label {
    padding-left: 0;
    text-align: left; }
  .payment form .btn-link {
    padding: 0; }
  .payment form .form-group {
    margin-bottom: 12px; }
    .payment form .form-group.has-error .help-block {
      display: block !important; }
    @media screen and (max-width: 767px) {
      .payment form .form-group {
        margin-left: 0;
        margin-right: 0; }
        .payment form .form-group, .payment form .form-group > div {
          padding: 0; } }
  .payment form .coupon-wrapper {
    display: block; }
    .payment form .coupon-wrapper .coupon-input .inner {
      height: 0;
      overflow: hidden;
      transition: height 0.3s ease-out;
      -webkit-transition: height 0.3s ease-out;
      -moz-transition: height 0.3s ease-out; }
    .payment form .coupon-wrapper .coupon-input .input-group button {
      margin-left: 10px; }
    .payment form .coupon-wrapper .coupon-input .input-group input {
      border-radius: 4px; }
    .payment form .coupon-wrapper .coupon-input.open .inner {
      height: 100px; }
  .payment form .expire-month-col {
    padding-right: 5px; }
  .payment form .expire-year-col {
    padding-left: 5px; }
  @media screen and (max-width: 991px) and (min-width: 768px) and (orientation: portrait) {
    .payment .sidebar {
      margin-left: 0;
      padding-left: 0; } }
  .payment .sidebar h3 {
    margin: 32px 0 20px; }
    .payment .sidebar h3:first-child {
      margin-top: 20px; }
      @media screen and (min-width: 992px) {
        .payment .sidebar h3:first-child {
          margin-top: 25px; } }
  .payment .sidebar__textarea {
    margin-top: 14px;
    text-align: justify;
    height: 109px; }
    .payment .sidebar__textarea, .payment .sidebar__textarea::placeholder {
      font-size: 1em;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 300;
      color: #9b9b9b;
      line-height: 1.43; }
      @media screen and (max-width: 767px) {
        .payment .sidebar__textarea, .payment .sidebar__textarea::placeholder {
          font-size: 1em; } }
  .payment .sidebar__table-grid__row {
    align-items: center;
    font-size: 1em;
    font-family: "Nunito Sans", sans-serif;
    color: #3462ae; }
    .payment .sidebar__table-grid__row__key {
      width: 70%; }
    .payment .sidebar__table-grid__row__key--summary {
      width: 60%; }
    .payment .sidebar__table-grid__row__value {
      width: 60%;
      text-align: right; }
    .payment .sidebar__table-grid__row.nett-total {
      font-weight: bold;
      margin-top: 10px; }
  @media screen and (min-width: 992px) {
    .payment .sidebar__table-grid__row__key {
      width: 70%; } }
  @media screen and (max-width: 767px) {
    .payment .sidebar__table-grid__row {
      font-size: 1em; }
      .payment .sidebar__table-grid__row__key {
        width: 80%; }
      .payment .sidebar__table-grid__row:nth-child(4) .sidebar__table-grid__row__key {
        width: 50%; } }
  .payment .sidebar img {
    margin-top: 24px;
    width: 100%;
    max-height: 114px;
    object-fit: cover; }
    @media screen and (min-width: 992px) {
      .payment .sidebar img {
        max-height: 152px;
        margin-top: 20px; } }
  .payment .sidebar > .wrapper {
    background-color: rgba(217, 215, 214, 0.59);
    padding: 0 22px 26px; }
    .payment .sidebar > .wrapper::before, .payment .sidebar > .wrapper::after {
      content: ' ';
      display: table; }
    .payment .sidebar > .wrapper::after {
      clear: both; }
    .payment .sidebar > .wrapper .table td {
      border: 0;
      padding: 8px; }
    @media screen and (max-width: 767px) {
      .payment .sidebar > .wrapper > h3 {
        padding: 0; } }
    @media screen and (min-width: 992px) {
      .payment .sidebar > .wrapper {
        padding-right: 54px;
        padding-left: 54px; } }
  .payment .sidebar ul.additional_requests {
    padding: 0;
    margin-bottom: 10px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap; }
    .payment .sidebar ul.additional_requests li {
      width: 70%; }
      .payment .sidebar ul.additional_requests li label {
        font-weight: 400;
        cursor: pointer; }
        .payment .sidebar ul.additional_requests li label input {
          margin-right: 5px; }
        .payment .sidebar ul.additional_requests li label.checkbox-label {
          font-size: 1em;
          color: #3462ae;
          padding: 0 0 12px 26px; }
          .payment .sidebar ul.additional_requests li label.checkbox-label .checkmark, .payment .sidebar ul.additional_requests li label.checkbox-label .checkmark:after {
            width: 16px;
            height: 16px; }
          .payment .sidebar ul.additional_requests li label.checkbox-label .checkmark:after {
            background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZmlsbD0iIzE0YTY1MSIgc3Ryb2tlPSIjMTRhNjUxIiBkPSJNLjUuNWgxMXYxMUguNXoiLz4KICAgICAgICA8cGF0aCBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTIgNi4xNTVMNC43MTMgOWw1LjIxMS03Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center;
            background-size: 16px; }
      @media screen and (min-width: 992px) {
        .payment .sidebar ul.additional_requests li {
          width: 50%; } }
    @media screen and (max-width: 767px) {
      .payment .sidebar ul.additional_requests {
        flex-direction: column;
        margin-bottom: 18px; }
        .payment .sidebar ul.additional_requests li label.checkbox-label {
          padding: 0 0 10px 28px;
          font-size: 1em; }
        .payment .sidebar ul.additional_requests .expand-link .caret {
          margin-left: 8px;
          border-width: 8px; } }
  .payment .sidebar .notes {
    font-size: 1em;
    font-family: "Nunito Sans", sans-serif;
    color: #3462ae;
    margin-top: 8px;
    display: block; }
  @media screen and (max-width: 767px) {
    .payment .sidebar {
      padding: 0 1px;
      font-size: 1em; } }
  .payment .bold {
    font-weight: bold; }
  .payment .summary h2 {
    color: #5a5a5a; }
  .payment .summary .btn {
    border: 1px solid #d9d7d6;
    font-weight: bold; }
  .payment .charge-amount h2 {
    font-size: 25px;
    text-align: center; }
